import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useState, useEffect, FC } from "react";
import { testimonials } from "./constant";
import { TestimonialCard } from "./TestimonialCard";

export const TestimonialSection: FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const itemsPerView = 1;
  const totalItems = testimonials.length;

  const handleScroll = () => {
    if (currentIndex >= 1) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  const handleNext = () => {
    if (currentIndex < totalItems - itemsPerView) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  useEffect(() => {
    handleScroll();
  }, [currentIndex]);

  return (
    <section className="bg-white py-8">
      <div className="md:py-12 xl:py-12 px-5 md:px-16">
        <div className="w-full referral-container">
          <div className="flex justify-between items-center">
            <p className="text-3xl font-SatoshiBold text-bblack-1 mb-12 lg:mb-0">
              What users are saying
            </p>
            <div className="hidden lg:flex gap-6">
              <button
                onClick={handlePrev}
                className="flex justify-center items-center border border-black rounded-full w-8 h-8"
              >
                <FiChevronLeft size={16} />
              </button>
              <button
                onClick={handleNext}
                className="flex justify-center items-center border border-black rounded-full w-8 h-8"
              >
                <FiChevronRight size={16} />
              </button>
            </div>
          </div>
          <div className="lg:hidden flex flex-col gap-8">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                message={testimonial.message}
                name={testimonial.name}
                title={testimonial.title}
                backgroundColor={testimonial.backgroundColor}
                showSecondColor={testimonial.showSecondColor}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={` w-full ${isScrolling ? "" : "mx-auto ml-auto mr-0 pl-16 live-projects "}`}
      >
        <div className="hidden lg:block w-full overflow-x-hidden">
          <div
            className={`flex gap-5 lg:transition-transform duration-500`}
            style={{
              transform: `translateX(-${currentIndex * 70}%)`,
            }}
          >
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                message={testimonial.message}
                name={testimonial.name}
                title={testimonial.title}
                backgroundColor={testimonial.backgroundColor}
                showSecondColor={testimonial.showSecondColor}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
