const faqData = [
  {
    question: "How Long will the process takes?",
    answer: "You can sign up and create a project on bnkle within 7 minutes.",
  },
  {
    question: "How many contractors can I choose from?",
    answer:
      "If you select our Standard and Enterprise Plans, you will have access to the entire pool of contractors on the platform. For the Premium Plan, your dedicated project manager will provide a shortlist of five verified contractors that fit your project for your review.",
  },
  {
    question: "How do I ensure my money is safe?",
    answer:
      "You may require that contractors provide Insurance Bonds or Advanced Payment Guarantees to protect you from any loss.",
  },
  {
    question: "Are there legal documents to be signed?",
    answer:
      "Yes, you wll be required to sign contract documents if you award or are awarded a contract on the platform.",
  },

  {
    question: "How can Bnklé help me manage my construction project?",
    answer:
      "Bnklé provides a construction management software tool that helps construction professionals manage and organize their projects, including tasks such as budgeting, scheduling, and document management.",
  },
  {
    question:
      "What types of features does Bnklé construction management software application offer?",
    answer:
      "The range of features for construction management includes project scheduling, budgeting, document management, and communication tools.\n In the future, we will offer features such as time tracking, resource allocation, and quality control.",
  },
  {
    question: "Can I use Bnklé on any device?",
    answer:
      "Bnklé is a web-based or cloud-based tools, which means they can be accessed from any device with an internet connection. Native mobile apps for iOS and Android devices are coming soon.",
  },
  {
    question: "How does Bnklé help with budgeting?",
    answer:
      "Bnklé helps you with budgeting by providing tools to track your costs and expenses, as well as generate reports to help you identify any potential cost overruns. This ensures that your projects stay on budget and that any potential issues can be addressed before they become major problems.",
  },
  {
    question: "Can Bnklé help me with scheduling?",
    answer:
      "Yes, Bnklé helps you with scheduling by providing you tools to create and track project schedules, as well as assign tasks to team members. This can help you ensure that projects stay on track and that all team members are aware of their responsibilities.",
  },
  {
    question: "Does Bnklé help with document management?",
    answer:
      "Bnklé helps with document management by providing a central repository for all project-related documents. This can include contracts, plans, drawings, and other important documents, and can help ensure that all team members have access to the most up-to-date versions of these documents.",
  },
];

let general = [
  {
    question: "How Long will it take to create a project?",
    answer: "You can sign up and create a project within 5 minutes",
  },
  {
    question: "How many contractors can I choose from?",
    answer:
      "For the Premium Plan, your dedicated project manager will provide a shortlist of five verified contractors that fit your project for your review.",
  },
  {
    question: "How do I ensure my money is safe?",
    answer:
      "You may require that contractors provide Insurance Bonds or Advanced Payment Guarantees to protect you from any loss.",
  },
  {
    question: "Are there legal documents to be signed?",
    answer:
      "Yes, you will be required to sign contract documents if you award a contract on the platform.",
  },
  {
    question: "How does Bnklé help me manage my construction project?",
    answer:
      "Bnklé provides a technology enabled project management service. We handhold you through the entire design and contracting process and also have boots on the ground for independent supervision and reporting on your project.",
  },
  {
    question:
      "What types of features does Bnklé construction management software application offer?",
    answer:
      "The range of features for construction management includes project scheduling, budgeting, procurement management, document management, and communication tools. Our solution provides schedule tracking, resource allocation, and quality control features.",
  },
  {
    question: "Can I use Bnklé on any device?",
    answer:
      "Bnklé is a web-based or cloud-based tool, which mean it can be accessed from any device with an internet connection. Native mobile apps for iOS and Android devices are coming soon.",
  },
  {
    question: "How does Bnklé help with budgeting?",
    answer:
      "Bnklé helps you with budgeting by providing tools to track your costs and expenses, as well as generate reports to help you identify any potential cost overruns. This ensures that your projects stay on budget and that any potential issues can be addressed before they become major problems.",
  },
  {
    question: "Can Bnklé help me with scheduling?",
    answer:
      "Yes, Bnklé helps you with scheduling by providing you tools to create and track project schedules. It also prompts you ahead of tasks and notifies you of delayed tasks. This can help you ensure that projects stay on track and that all team members are aware of their responsibilities.",
  },
  {
    question: "Does Bnklé help with document management?",
    answer:
      "Bnklé helps with document management by providing a central repository for all project-related documents. This can include contracts, plans, drawings, and other important documents, and can help ensure that all team members have access to the most up-to-date versions of these documents.",
  },
];

const ownerfaq = [
  {
    question: "How Long will it take to create a project?",
    answer: "You can sign up and create a project within 5 minutes",
  },
  {
    question: "How many contractors can I choose from?",
    answer:
      "For the Premium Plan, your dedicated project manager will provide a shortlist of five verified contractors that fit your project for your review.",
  },
  {
    question: "How do I ensure my money is safe?",
    answer:
      "You may require that contractors provide Insurance Bonds or Advanced Payment Guarantees to protect you from any loss.",
  },
  {
    question: "Are there legal documents to be signed?",
    answer:
      "Yes, you will be required to sign contract documents if you award a contract on the platform.",
  },
  {
    question: "How does Bnklé help me manage my construction project?",
    answer:
      "Bnklé provides a technology enabled project management service. We handhold you through the entire design and contracting process and also have boots on the ground for independent supervision and reporting on your project.",
  },
  {
    question:
      "What types of features does Bnklé construction management software application offer?",
    answer:
      "The range of features for construction management includes project scheduling, budgeting, procurement management, document management, and communication tools. Our solution provides schedule tracking, resource allocation, and quality control features.",
  },
  {
    question: "Can I use Bnklé on any device?",
    answer:
      "Bnklé is a web-based or cloud-based tool, which mean it can be accessed from any device with an internet connection. Native mobile apps for iOS and Android devices are coming soon.",
  },
  {
    question: "How does Bnklé help with budgeting?",
    answer:
      "Bnklé helps you with budgeting by providing tools to track your costs and expenses, as well as generate reports to help you identify any potential cost overruns. This ensures that your projects stay on budget and that any potential issues can be addressed before they become major problems.",
  },
  {
    question: "Can Bnklé help me with scheduling?",
    answer:
      "Yes, Bnklé helps you with scheduling by providing you tools to create and track project schedules. It also prompts you ahead of tasks and notifies you of delayed tasks. This can help you ensure that projects stay on track and that all team members are aware of their responsibilities.",
  },
  {
    question: "Does Bnklé help with document management?",
    answer:
      "Bnklé helps with document management by providing a central repository for all project-related documents. This can include contracts, plans, drawings, and other important documents, and can help ensure that all team members have access to the most up-to-date versions of these documents.",
  },
];

const contractorFaq = [
  {
    question: "What do I need to set up a profile on Bnkle?",
    answer:
      "you can sign up with basic information about your company within 10 minutes. To get verified and invited to bid on projects, you will need to fill out details on your company profile which can take another 10 - 15 minutes.",
  },
  {
    question: "How does Bnkle select contractors to invite to bid on projects?",
    answer:
      "To increase your chances of being invited to bid on projects, ensure that your company profile is fully filled out. Also, you are only likely to be invited to bid on projects where your company is demonstrably active.",
  },
  {
    question: "How does Bnklé help me better manage construction projects?",
    answer:
      "The suite of features on Bnklé help to improve the efficiency of running your contracting business. You will be better able to see across the projects you are handling and will be able to keep a close eye on all the resources in your projects.",
  },
  {
    question:
      "What types of features does Bnklé construction management software application offer?",
    answer:
      "The range of features for construction management includes project scheduling, budgeting, procurement management, document management, and communication tools. Our solution provides schedule tracking, resource allocation, and quality control features.",
  },
  {
    question: "Can I use Bnklé on any device?",
    answer:
      "Bnklé is a web-based or cloud-based tool, which mean it can be accessed from any device with an internet connection. Native mobile apps for iOS and Android devices are coming soon.",
  },
  {
    question: "How does Bnklé help with budgeting?",
    answer:
      "Bnklé helps you with budgeting by providing tools to track your costs and expenses, as well as generate reports to help you identify any potential cost overruns. This ensures that your projects stay on budget and that any potential issues can be addressed before they become major problems.",
  },
  {
    question: "Can Bnklé help me with scheduling?",
    answer:
      "Yes, Bnklé helps you with scheduling by providing you tools to create and track project schedules. It also prompts you ahead of tasks and notifies you of delayed tasks. This can help you ensure that projects stay on track and that all team members are aware of their responsibilities.",
  },
  {
    question: "Does Bnklé help with document management?",
    answer:
      "Bnklé helps with document management by providing a central repository for all project-related documents. This can include contracts, plans, drawings, and other important documents, and can help ensure that all team members have access to the most up-to-date versions of these documents.",
  },
];

const enterpriseFaq = [
  {
    question: "What do I need to onboard my projects on Bnkle?",
    answer:
      "You can sign up and create an account on Bnklé within 5 minutes. Onboarding projects will depend on whether you are onboarding an individual project or a cluster of projects. Individual projects can be created in less than 5 minutes, while clusters will depend on how many projects are within a cluster.",
  },
  {
    question:
      "Can I onboard my team and contractors who work for my company on Bnkle.",
    answer:
      "Yes, Bnklé allows you to onboard everyone connected to your project in different roles on the platform. Onboarding is done simply by sending out invites to their email with a few clicks.",
  },
  {
    question: "How does Bnklé help me better manage construction projects?",
    answer:
      "The suite of features on Bnklé help to improve the efficiency of running your real estate development business. The Bnklé data rich dashboard allows you to see across the projects you are handling, and so keep a close eye on how your entire portfolio is developing while having access to granular details about the resources your projects are using.",
  },

  {
    question:
      "What types of features does Bnklé construction management software application offer?",
    answer:
      "The range of features for construction management includes project scheduling, budgeting, procurement management, document management, and communication tools. Our solution provides schedule tracking, resource allocation, and quality control features.",
  },
  {
    question: "Can I use Bnklé on any device?",
    answer:
      "Bnklé is a web-based or cloud-based tool, which mean it can be accessed from any device with an internet connection. Native mobile apps for iOS and Android devices are coming soon.",
  },
  {
    question: "How does Bnklé help with budgeting?",
    answer:
      "Bnklé helps you with budgeting by providing tools to track your costs and expenses, as well as generate reports to help you identify any potential cost overruns. This ensures that your projects stay on budget and that any potential issues can be addressed before they become major problems.",
  },
  {
    question: "Can Bnklé help me with scheduling?",
    answer:
      "Yes, Bnklé helps you with scheduling by providing you tools to create and track project schedules. It also prompts you ahead of tasks and notifies you of delayed tasks. This can help you ensure that projects stay on track and that all team members are aware of their responsibilities.",
  },
  {
    question: "Does Bnklé help with document management?",
    answer:
      "Bnklé helps with document management by providing a central repository for all project-related documents. This can include contracts, plans, drawings, and other important documents, and can help ensure that all team members have access to the most up-to-date versions of these documents.",
  },
];

let x = {
  question: "",
  answer: "",
};
const testimonials = [
  {
    message:
      "The Bnklé platform was especially important as a central point to constantly communicate the implementation of the project, allowing us to see pictures of work progress, view/review invoices, and monitor spending, among others. This level of communication, transparency, and coordination was especially crucial as I was overseas during the start of the project. Needless to say, the result is nothing short of impressive.",
    name: "Bukky Shonibare",
    title: "Executive Director, Invictus Africa",
    backgroundColor: "#FFC59A",
  },
  {
    message:
      "Bnkle is a wonderful project management tool. It has enabled me monitor my project remotely. From the negotiations to the actual site implementation with visual updates. It also provides a seamless interface for all the actors on the project.",
    name: "Samson Opaluwah. PhD.",
    title: "Chairman, Council of Registered Builders of Nigeria (CORBON)",
    backgroundColor: "#DAEDBB",
    showSecondColor: true,
  },
];

export {
  faqData,
  testimonials,
  general,
  contractorFaq,
  ownerfaq,
  enterpriseFaq,
};
