import heroImg from "assets/images/homehero.png";
import heroImgMobile from "assets/images/homeheromobile.png";

const Hero = () => {
    return (
        <div className=' py-8 md:py-16 xl:py-12 px-5 md:px-16 lg:pr-0 home-hero-parent'>
            <div className='relative home-hero-container lg:overflow-x-hidden'>
                <div className='flex flex-col gap-10 lg:gap-20 lg:flex-row xl:gap-40 lg:justify-start'>
                    <div className='lg:w-[450px] xl:w-[500px] flex-shrink-0 pt-8'>
                        <h1 className='text-[40px] text-center md:text-start md:text-[50px] md:leading-[72px] xl:text-6xl xl:leading-[72px] font-SatoshiBold'>
                            Manage Your Construction Projects remotely
                        </h1>
                        <p className='pt-8 pb-4 text-xl lg:w-9/12'>
                            Get projects done on time and on budget from
                            anywhere.
                        </p>
                        <button
                            className='w-full lg:w-max font-inter font-medium text-lg bg-bblue text-white py-[10px] px-[18px] md:py-3 md:px-8 rounded-md hover:bg-blueShades-1 active:bg-blueShades-0'
                            onClick={() => {
                                window.location.assign(
                                    `https://auth.${process.env.REACT_APP_DOMAIN}/select-persona?type=signup`
                                );
                            }}
                        >
                            Sign Up
                        </button>
                    </div>
                    <img
                        src={heroImg}
                        alt=''
                        className=' hidden lg:block flex-grow-0 w-[700px] h-[482px] xl:w-[800px] xl:h-[551px] 2xl:w-[912px] 2xl:h-[628px]'
                    />
                    <img
                        src={heroImgMobile}
                        alt=''
                        className='w-full lg:hidden'
                    />
                </div>
            </div>
        </div>
    );
};
export default Hero;
