import PersonaCard from "./PersonaCard";
import { personas } from "../constants";
const Personas = () => {
    return (
        <div className='bg-[#0D182C] text-white py-8 md:py-16 px-5 md:px-16'>
            <div className='flex flex-col items-center gap-14 referral-container'>
                <div className='flex flex-col md:justify-center md:items-center'>
                    <h2 className='text-4xl xl:text-5xl font-SatoshiMedium'>
                        Who is it for?
                    </h2>
                    <p className='w-full md:w-7/12 lg:w-6/12 pt-3 text-bidsbg md:text-center'>
                        Bnkle provides property developers, project owners,
                        contractors, consultants and vendors the tools and
                        resources to deliver exceptional projects.{" "}
                    </p>
                </div>
                <div className='grid md:grid-cols-2 lg:grid-cols-3 md:gap-5'>
                    {personas.map((persona) => {
                        return <PersonaCard key={persona.id} {...persona} />;
                    })}
                </div>
            </div>
        </div>
    );
};
export default Personas;
