import { FeaturesIcon } from "assets/icons";
import VerifyIcon from "assets/icons/verify.svg";
import ContractorFeature5 from "assets/contractorFeature5.png";
import ContractorFeature6 from "assets/contractorFeature6.png";
import { FC } from "react";
import {
  CardComponent,
  Subtitle,
  Title,
  SectionBadge,
  FeatureItemCard,
} from "components";
import { features } from "../constants";

export const FeaturesSection: FC = () => (
  <div className="bg-bgSecondary md:px-12">
    <div className="flex flex-col justify-center items-center gap-8 px-4 md:px-0 py-16 max-w-[1234px] mx-auto">
      <SectionBadge title="Features" icon={FeaturesIcon} isWhiteColor />
      <div className="flex flex-col justify-center items-center gap-6  text-center max-w-[720px]">
        <Title text="Here is why you should use Bnkle" />
        <Subtitle
          text="You can finally access the tools and resources that help you deliver a project seamlessly, all in one place. Powerful tools like..."
          className="text-center"
        />
      </div>
      <div className="grid md:grid-cols-2 gap-4">
        {features.map((feature, index) => (
          <FeatureItemCard
            key={index}
            badgeTitle={feature.badgeTitle}
            badgeIcon={feature.badgeIcon}
            cardTitle={feature.cardTitle}
            cardBody={feature.cardBody}
            image={feature.image}
            isWhiteColor={feature.isWhiteColor}
            isDeepBlueColor={feature.isDeepBlueColor}
            largeScreenButtonText="Create profile"
            smallScreenButtonText="Create profile"
            linkTo={`https://auth.${process.env.REACT_APP_DOMAIN}`}
          />
        ))}
      </div>
      <div className="max-w-full flex flex-col lg:flex-row justify-center lg:justify-between gap-16 rounded-xl  lg:rounded-br-none lg:rounded-3xl pt-12 mt-2 bg-white">
        <div className="lg:max-w-[496px]">
          <CardComponent
            badgeTitle="Get verified"
            badgeIcon={VerifyIcon}
            cardTitle="Get Verified and climb to the top of the list of companies that project owners consider first."
            cardBody="Go through our simple vetting process and receive our verification mark that project owners trust."
            largeScreenButtonText="Create profile"
            isWhiteColor
            linkTo={`https://auth.${process.env.REACT_APP_DOMAIN}`}
          />
        </div>

        <div className="flex lg:pb-[4em] lg:w-[50%] w-full justify-end items-end">
          <div className="lg:block hidden">
            <img src={ContractorFeature5} alt="Company Profile" />
          </div>

          <div className="block lg:hidden self-end w-[80%]">
            <img src={ContractorFeature6} alt="Company Profile" />
          </div>
        </div>
      </div>
    </div>
  </div>
);
