import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "./pages/landingpage";
import Enterprise from "./pages/Enterprise";
import Contractor from "./pages/contractor";
import HomePage from "./pages/homepage";
import NotFound from "./pages/NotFound";
import Login from "./pages/login";
import { useContext } from "react";
import { AuthContext } from "context";
import Banner from "components/Banner";
import Referrals from "./pages/referrals";
import Redirect from "pages/referrals/Redirect";
import ContactUs from "pages/contact";
import { DoctorsPage, EnterprisePage } from "pages";
import { Layout } from "components";
import { OwnerPage } from "pages/owner/OwnerPage";
import { Pricing } from "pages/pricing/Pricing";
import EnterprisePlan from "pages/pricing/enterprise/EnterprisePlan";
import PremiumPlan from "pages/pricing/premium/PremiumPlan";
import { PrivacyPage } from "pages/privacy/PrivacyPage";
import ScrollToTop from "components/ScrollToTop";

function App() {
  let [auth] = useContext(AuthContext);
  return (
    <>
      <ScrollToTop />
      <Routes>
        {auth === true || !process.env.REACT_APP_IS_BNKLE ? (
          <>
            <Route index element={<HomePage />} />

            <Route path="/contractor" element={<Contractor />} />
            <Route path="/canpad" element={<Navigate to={"/owner"} />} />
            <Route path="/consultant" element={<Contractor isConsultant />} />
            <Route path="/referral">
              <Route path="" index element={<Referrals />} />
              <Route path=":refId" index element={<Redirect />} />
              <Route />
            </Route>
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/" element={<Layout />}>
              <Route path="doctor" element={<DoctorsPage />} />
              <Route path="owner" element={<OwnerPage />} />
              <Route path="enterprise" element={<EnterprisePage />} />
              <Route path="policy" element={<PrivacyPage />} />
              <Route path="pricing" element={<Pricing />}>
                <Route index element={<Navigate to="enterprise" replace />} />
                <Route path="enterprise" element={<EnterprisePlan />} />
                <Route path="premium" element={<PremiumPlan />} />
              </Route>
            </Route>
          </>
        ) : null}
        <Route path="*" element={<NotFound />} />
        <Route path="/admin-login" element={<Login />} />
      </Routes>
    </>
  );
}

export default App;
