import React, { useState } from "react";
import { IoChevronUpOutline, IoChevronDown } from "react-icons/io5";
import { GoDash } from "react-icons/go";
import { GrCheckmark } from "react-icons/gr";
import Button from "components/shared/Button";
import { BsDashLg } from "react-icons/bs";
import CustomDropdown from "pages/pricing/components/CustomDropdown";
import frameTick from "../../../../assets/pricing/Frame.png";
import frameDash from "../../../../assets/pricing/Frame2.png";
import tick from "../../../../assets/pricing/Vector.png";
import { useNavigate } from "react-router-dom";
import { ContactModal } from "pages/enterprises/components/HeroSection";
import { Availability, ComparePlanProps } from "../monthly/ComparePlans";

const CompareAnuallyPlans: React.FC<ComparePlanProps> = ({
  plans,
  compareItems,
  selectedPlan,
  setSelectedPlan,
}) => {
  const [openRows, setOpenRows] = useState<number[]>([]);

  const toggleRow = (index: number) => {
    if (openRows.includes(index)) {
      setOpenRows(openRows.filter((i) => i !== index));
    } else {
      setOpenRows([...openRows, index]);
    }
  };
  const defaultPlan = plans[0].title;
  const activePlan = selectedPlan || defaultPlan;
  let [modal, setModal] = useState(false);
  let navigate = useNavigate();

  const toggleModal = () => {
    setModal(!modal);
  };
  return (
    <>
      {modal && <ContactModal closer={toggleModal} />}

      <div className="my-20">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-SatoshiMedium mb-14">
          Compare Plans
        </h2>

        <div className="grid grid-cols-5 items-center sticky top-20 bg-white">
          <div className="feature pt-6 font-SatoshiMedium mt-8 md:mt-0 col-span-2 md:col-span-1">
            Features
          </div>

          <div className="block md:hidden col-span-3 flex items-center">
            <CustomDropdown
              plans={plans}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
          </div>

          {plans.map((plan, index) => (
            <div key={index} className={`py-4 pl-8 font-bold hidden md:block`}>
              <div
                className="pr-10 text-xl"
                style={{
                  color: selectedPlan === plan.title ? "#437ADB" : "",
                }}
              >
                {plan.title}
              </div>
              <Button
                onClick={toggleModal}
                label="Contact Us"
                className="mt-4 font-SatoshiLight w-5/6  !px-0 lg:px-18 py-3 rounded-full text-[#fff] hidden md:block"
                style={{
                  backgroundColor:
                    selectedPlan === plan.title ? "#ECF2FB" : "#437ADB",
                  color: selectedPlan === plan.title ? "#437ADB" : "#fff",
                }}
              />
            </div>
          ))}
        </div>

        {/* Compare rows */}
        {compareItems.map((item, rowIndex) => (
          <div key={rowIndex}>
            <div
              className={`grid ${
                item.feature !== "" ? "border-t border-[#0C0F13]" : ""
              }`}
            >
              <div
                className="font-semibold flex justify-between items-center cursor-pointer py-3"
                onClick={() => toggleRow(rowIndex)}
              >
                <span>{item.feature}</span>

                {item.feature !== "" && (
                  <button onClick={() => toggleRow(rowIndex)} className="ml-2">
                    {openRows.includes(rowIndex) ? (
                      <IoChevronUpOutline
                        className="w-5 h-6"
                        style={{ color: "#437ADB" }}
                      />
                    ) : (
                      <IoChevronDown className="w-5 h-6" />
                    )}
                  </button>
                )}
              </div>
            </div>

            {(item.feature === "" || openRows.includes(rowIndex)) &&
              item.subFeatures.map((subFeature, subIndex) => (
                <div
                  key={subIndex}
                  className="grid grid-cols-5 md:grid-cols-5 border-t text-[12px] md:text-base"
                >
                  <div
                    onClick={() => toggleRow(rowIndex)}
                    className="p-1 flex justify-start whitespace-nowrap col-span-4 md:col-span-1"
                  >
                    {subFeature.name}
                  </div>

                  {plans.map((plan, colIndex) => (
                    <div
                      key={colIndex}
                      className={`p-2 flex justify-center items-center ${
                        plan.title === activePlan ? "" : "hidden md:flex"
                      }`}
                    >
                      {typeof subFeature.availability[colIndex] ===
                      "boolean" ? (
                        subFeature.availability[colIndex] ? (
                          <>
                            <img
                              src={tick}
                              alt="Tick"
                              className=" block md:hidden"
                            />

                            <img
                              src={frameTick}
                              alt="Tick"
                              className="w-6 h-6 hidden md:block"
                            />
                          </>
                        ) : (
                          <>
                            <GoDash className="w-20 h-4  block md:hidden text-[#437ADB]" />

                            <img
                              src={frameDash}
                              alt="Dash"
                              className="w-6 h-6 hidden md:block"
                            />
                          </>
                        )
                      ) : (
                        <span className="text-[#222B34] ">
                          {subFeature.availability[colIndex]}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default CompareAnuallyPlans;
