import { FC } from "react";

interface Props {
  text: string;
  className?: string;
  textColor?: string;
}

export const Title: FC<Props> = ({ text, className, textColor }) => {
  return (
    <>
      <p
        className={`font-bold font-satoshi ${textColor ?? "text-bblack-1"} text-3xl ${className}`}
      >
        {text}
      </p>
    </>
  );
};
