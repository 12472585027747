import coLabsLogo from "assets/svg/colabs-logo.svg";
import invictusLogo from "assets/svg/invictus-logo.svg";
import ennovateLogo from "assets/svg/ennovate-logo.svg";
import prexLogo from "assets/svg/prex-logo.svg";
import azalea from "assets/images/azalea.png";

const SupportSection = () => {
  return (
    <div className="py-8 md:py-16 xl:py-20 px-5 md:px-16">
      {" "}
      <h2 className="xl:text-xl font-SatoshiMedium my-6 mx-10 text-[#222B34] ">
        Trusted by:
      </h2>
      <div className="flex flex-col gap-8 lg:gap-0 lg:flex-row lg:justify-between items-center referral-container">
        <section className="flex w-full flex-col lg:flex-row items-center gap-6 md:gap-8">
          <div className="flex justify-evenly flex-wrap  w-full items-center gap-4 md:gap-8 lg:gap-12">
            <img
              src={invictusLogo}
              alt="Invictus"
              className="w-20 md:w-[114px] h-[32px] xl:w-[183px] xl:h-[48px]"
            />

            <img
              src={ennovateLogo}
              alt="Enovate Lab"
              className="w-20 md:w-[114px] h-[32px] xl:w-[183px] xl:h-[48px]"
            />
            <img
              src={prexLogo}
              alt="Prex"
              className="w-20 md:w-[114px] h-[32px] xl:w-[124px] xl:h-[36px]"
            />
            <img
              src={azalea}
              alt="azalea"
              className="w-20 md:w-[114px] object-contain h-[32px] xl:w-[183px] xl:h-[48px]"
            />
          </div>
        </section>
      </div>
    </div>
  );
};
export default SupportSection;
