import React, { useState, useRef, useEffect } from "react";
import { IoChevronUpOutline, IoChevronDown } from "react-icons/io5";

interface Plan {
  title: string;
}

interface CustomDropdownProps {
  plans: Plan[];
  selectedPlan: string | null;
  setSelectedPlan: (plan: string) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  plans,
  selectedPlan,
  setSelectedPlan,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(selectedPlan);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleOptionClick = (plan: Plan) => {
    setActiveOption(plan.title);
    setSelectedPlan(plan.title);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative block md:hidden col-span-3 mt-14 "
      ref={dropdownRef}
    >
      <button
        className="border py-1 px-6 w-full rounded-md border-[#9099A8] bg-white shadow-md flex gap-2 items-center justify-between"
        onClick={toggleDropdown}
        style={{
          backgroundColor: activeOption ? "#ECF2FB" : "#fff",
          color: activeOption ? "#437ADB" : "#222B34",
        }}
      >
        <span>{activeOption || "Select a Plan"}</span>
        {isOpen ? (
          <IoChevronUpOutline className="w-3 h-3" />
        ) : (
          <IoChevronDown className="w-3 h-3" />
        )}
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1  rounded-lg bg-white shadow-lg">
          {plans.map((plan, index: number) => (
            <div
              key={index}
              className={`py-1 mx-2 my-2 px-2 cursor-pointer text-[12px] hover:bg-blue-500 hover:text-white text-left ${
                activeOption === plan.title
                  ? "  bg-blue-500 rounded-md text-white text-left"
                  : "text-black"
              }`}
              onClick={() => handleOptionClick(plan)}
            >
              {plan.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
