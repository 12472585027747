import { useState } from "react";
import Monthly from "../enterprise/monthly/Monthly";
import Annually from "../enterprise/annually/Annually";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("monthly");

  return (
    <div className="w-full flex flex-col items-center ">
      <div className="flex justify-center items-center my-6 border border-[#EAECF0] rounded-full bg-white">
        <div
          className={`px-6 py-1.5   rounded-full ${activeTab === "monthly" ? "bg-[#437ADB] text-white" : "bg-white"} cursor-pointer`}
          onClick={() => setActiveTab("monthly")}
        >
          Monthly
        </div>
        <div
          className={`px-6 py-1.5  rounded-full ${activeTab === "annually" ? "bg-[#437ADB] text-white" : "bg-white"} cursor-pointer`}
          onClick={() => setActiveTab("annually")}
        >
          Annually - save 15%
        </div>
      </div>

      <div className="mt-4 text-center">
        {activeTab === "monthly" ? <Monthly /> : <Annually />}
      </div>
    </div>
  );
};

export default Tabs;
