import React, { useEffect } from "react";
import Header from "components/newShared/Header";
import Hero from "./Hero";
import SupportSection from "./SupportSection";
import LiveProjects from "./LiveProjects";
import Personas from "./Personas";
import Features from "./Features";
import { TestimonialSection, EstimateSection } from "components";
import { FaqSection } from "components/containers/FaqSection";
import Footer from "components/newShared/Footer";

export default function HomePage() {
  useEffect(() => {
    document.title = "home";
  }, []);

  return (
    <div className="font-SatoshiRegular text-bblack-1">
      <Header
        signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
        signUpLink={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup?fromreferralPage=true`}
      />
      <div className="w-full h-full">
        <Hero />
        <SupportSection />
        <LiveProjects />
        <Personas />
        <Features />
        <TestimonialSection />
        <FaqSection />
        <EstimateSection />
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
}
