import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface ContactFormData {
	firstname: string;
	lastname: string;
	email: string;
	message: string;
}

interface ContactState {
	loading: boolean;
	success: boolean;
	successMessage: string | null;
	error: string | null;
}

// Initial state
const initialState: ContactState = {
	loading: false,
	success: false,
	successMessage: null,
	error: null,
};

// Async thunk for sending form data to the endpoint
export const sendContactForm = createAsyncThunk(
	"contact/sendContactForm",
	async (formData: ContactFormData, { rejectWithValue }) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_IAM_URL}/inquiries/contact-us`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						firstName: formData.firstname,
						lastName: formData.lastname,
						email: formData.email,
						description: formData.message,
					}),
				}
			);

			if (!response.ok) {
				const errorDetails = await response.text();
				throw new Error(
					`HTTP error! status: ${response.status} - ${errorDetails}`
				);
			}

			const result = await response.json();
			return result.message; 
		} catch (error:any) {
			return rejectWithValue(error.message);
		}
	}
);

// Create the slice
const contactSlice = createSlice({
	name: "contact",
	initialState,
	reducers: {
		resetState: () => initialState, 
	},
	extraReducers: (builder) => {
		builder
			.addCase(sendContactForm.pending, (state) => {
				state.loading = true;
				state.success = false;
				state.error = null;
				state.successMessage = null;
			})
			.addCase(sendContactForm.fulfilled, (state, action) => {
				state.loading = false;
				state.success = true;
				state.successMessage = action.payload as string; 
			})
			.addCase(sendContactForm.rejected, (state, action) => {
				state.loading = false;
				state.success = false;
				state.error = action.payload as string;
			});
	},
});

export const { resetState } = contactSlice.actions;
export default contactSlice.reducer;
