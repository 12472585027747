import { CapitalIcon, FeaturesIcon } from "assets/icons";
import { FC } from "react";
import { CardComponent, SectionBadge, FeatureItemCard } from "components";
import { features } from "../constant";
import { MarkImage } from "assets/svg";

export const FeaturesSection: FC = () => (
  <div className="bg-bgSecondary">
    <div className="py-8 md:py-16 xl:py-20 px-5 md:px-16 lg:px-16">
      <div className="flex flex-col justify-center items-center  referral-container gap-8">
        <SectionBadge title="Features" icon={FeaturesIcon} isWhiteColor />
        <div className="flex flex-col gap-6 lg:w-[740px] text-center">
          <p className="font-bold font-SatoshiBold text-5xl text-ashShade-10">
            Here is why you should use Bnkle
          </p>
          <p className="font-SatoshiMedium text-xl text-bash">
            You can finally access the tools and resources that help you deliver
            a project seamlessly. All in one place.
          </p>
        </div>
        <div className="grid lg:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <FeatureItemCard
              key={index}
              badgeTitle={feature.badgeTitle}
              badgeIcon={feature.badgeIcon}
              cardTitle={feature.cardTitle}
              cardBody={feature.cardBody}
              image={feature.image}
              isWhiteColor={feature.isWhiteColor}
              isDeepBlueColor={feature.isDeepBlueColor}
              largeScreenButtonText="Create a project"
              linkTo={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup`}
            />
          ))}
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-8 rounded-3xl pt-12 bg-white w-full lg:h-[480px]">
          <CardComponent
            badgeTitle="Secured capital"
            badgeIcon={CapitalIcon}
            cardTitle="Eliminate the risk of financial loss."
            cardBody="With bnkle, your project funds are 100% insured. "
            largeScreenButtonText="Create a project"
            isWhiteColor
            linkTo={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup`}
            showBadgeForLargeScreen
          />
          <div className="relative w-[50%] lg:w-fit flex pb-24 lg:mr-20 self-center">
            <div className="">
              <img src={MarkImage} alt="" />
            </div>
            <p className="absolute -right-12 xmd:right-0 lg:left-0 self-end text-4xl xmd:text-5xl lg:text-6xl font-satoshi font-black text-blueShades-3">
              100%
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
