import React from "react";
import { HeroSection } from "../components/HeroSection";
import Tabs from "../components/Tabs";

const EnterprisePlan = () => {
  return (
    <div>
      <HeroSection />
      <Tabs />
    </div>
  );
};

export default EnterprisePlan;
