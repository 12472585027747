import GridIcon from "assets/icons/grid-4.svg";
import CategoryIcon from "assets/icons/category.svg";
import ReceiptIcon from "assets/icons/receipt-item.svg";

import ContractorFeature1 from "assets/contractorFeature1.png";
import ContractorFeature2 from "assets/contractorFeature2.png";
import ContractorFeature3 from "assets/contractorFeature3.png";
import ContractorFeature4 from "assets/contractorFeature4.png";

import image42 from "assets/image42.svg";
import image13 from "assets/image13.svg";
import image16 from "assets/image16.svg";
import image14 from "assets/image14.svg";
import image29 from "assets/image29.svg";
import contractor1 from "assets/contractor1.png";
import contractor2 from "assets/contractor2.png";
import contractor3 from "assets/contractor3.png";
import contractor4 from "assets/contractor4.png";
import contractor5 from "assets/contractor5.png";

const faqData = [
  {
    question: "How Long will the process take?",
    answer: "You can sign up and create a project on bnkle within 7 minutes.",
  },
  {
    question: "How many contractors can I choose from?",
    answer:
      "If you select our Standard and Enterprise Plans, you will have access to the entire pool of contractors on the platform. For the Premium Plan, your dedicated project manager will provide a shortlist of five verified contractors that fit your project for your review.",
  },
  {
    question: "How do I ensure my money is safe?",
    answer:
      "You may require that contractors provide Insurance Bonds or Advanced Payment Guarantees to protect you from any loss.",
  },
  {
    question: "Are there legal documents to be signed?",
    answer:
      "Yes, you wll be required to sign contract documents if you award or are awarded a contract on the platform.",
  },

  {
    question: "How can Bnklé help me manage my construction project?",
    answer:
      "Bnklé provides a construction management software tool that helps construction professionals manage and organize their projects, including tasks such as budgeting, scheduling, and document management.",
  },
  {
    question:
      "What types of features does Bnklé construction management software application offer?",
    answer:
      "The range of features for construction management includes project scheduling, budgeting, document management, and communication tools.\n In the future, we will offer features such as time tracking, resource allocation, and quality control.",
  },
  {
    question: "Can I use Bnklé on any device?",
    answer:
      "Bnklé is a web-based or cloud-based tools, which means they can be accessed from any device with an internet connection. Native mobile apps for iOS and Android devices are coming soon.",
  },
  {
    question: "How does Bnklé help with budgeting?",
    answer:
      "Bnklé helps you with budgeting by providing tools to track your costs and expenses, as well as generate reports to help you identify any potential cost overruns. This ensures that your projects stay on budget and that any potential issues can be addressed before they become major problems.",
  },
  {
    question: "Can Bnklé help me with scheduling?",
    answer:
      "Yes, Bnklé helps you with scheduling by providing you tools to create and track project schedules, as well as assign tasks to team members. This can help you ensure that projects stay on track and that all team members are aware of their responsibilities.",
  },
  {
    question: "Does Bnklé help with document management?",
    answer:
      "Bnklé helps with document management by providing a central repository for all project-related documents. This can include contracts, plans, drawings, and other important documents, and can help ensure that all team members have access to the most up-to-date versions of these documents.",
  },
];

// let questions = [
//   {
//     question: "How Long will the process take?",
//     answer:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
//   },
//   {
//     question: "How many contractors can I choose from?",
//     answer:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
//   },
//   {
//     question: "How do I ensure my money is safe?",
//     answer:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
//   },
//   {
//     question: "Are there legal documents to be signed?",
//     answer:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
//   },
// ];

const features = [
  {
    badgeTitle: "One dashboard",
    badgeIcon: GridIcon,
    cardTitle: "Access all project information from one dashboard.",
    cardBody: "Keep track of all projects on a single dashboard.",
    image: ContractorFeature1,
    linkTo: "",
  },
  {
    badgeTitle: "Zero tender fees",
    badgeIcon: ReceiptIcon,
    cardTitle:
      "Forget tender fees. Submit bids online for multiple projects with a few clicks.",
    cardBody:
      "Get access to a steady flow of projects to bid for and submit your bids online in a transparent process. All for free.",
    image: ContractorFeature2,
    linkTo: "",
    isWhiteColor: true,
  },
  {
    badgeTitle: "Inventory Management",
    badgeIcon: GridIcon,
    cardTitle: "Get best prices for building materials from verified vendors.",
    cardBody:
      "Track material procurement and usage to eliminate theft and waste.",
    image: ContractorFeature3,
    linkTo: "",
    isWhiteColor: true,
  },
  {
    badgeTitle: "Real-time tracking",
    badgeIcon: CategoryIcon,
    cardTitle:
      "Track work progress and budget using our cutting-edge planning tools.",
    cardBody:
      "See real-time project status and share status reports with your clients with a few clicks.",
    image: ContractorFeature4,
    linkTo: "",
    isDeepBlueColor: true,
  },
];

const copiesForContractor = [
  {
    image: contractor5,
    title: "Access all project information from one dashboard.",
    subtitle:
      "Get access to a steady flow of projects to bid for and submit your bids online in a transparent process. All for free.",
  },
  {
    image: contractor4,
    title:
      "Forget tender fees. Submit bids online for multiple projects with a few clicks.",
    subtitle:
      "Get access to a steady flow of projects to bid for and submit your bids online in a transparent process. All for free.",
  },
  {
    image: contractor1,
    title: "Get best prices for building materials from verified vendors.",
    subtitle:
      "Access the widest variety of building material inventory from vendors across different project locations.",
  },
  {
    image: contractor2,
    title:
      "Track work progress and budget using our cutting-edge planning tools.",
    subtitle:
      "See real-time project status and share status reports with your clients with a few clicks.",
  },
  {
    image: contractor3,
    title:
      "Get Verified and climb to the top of the list of companies that project owners consider first.",
    subtitle:
      "Go through our simple vetting process and receive our verification mark that project owners trust.",
  },
];
const copies = [
  {
    image: image29,
    title:
      "Create and share your company’s online portfolio in minutes. For free.s",
    subtitle:
      "Access our curated pool of vetted contractors and consultants. Our rating system ensures that you find the perfect match for your project requirements.",
  },
  {
    image: image14,
    title: "Enjoy a simplified and faster bidding process at zero cost.",
    subtitle:
      "Conduct transparent online bidding process: Automate the tender process from issuing invitations to bid, to receiving and analysing bids.",
  },
  {
    image: image16,
    title: "Gain access to bid on multiple high value projects.",
    subtitle:
      "Our cloud based repository of project documents and decisions ensures that you can recall and review every detail of your project real time.",
  },
  {
    image: image13,
    title: "Access construction management tools to better manage projects.",
    subtitle:
      "Contractors provide insurance to cover the funds you disburse for the constuction of your project.",
  },
  {
    image: image42,
    title: "Get your company verified to improve your reliability/status.",
    subtitle:
      "Get increased visibility on project progress and allows you to quickly identify any issues or challenges and adjust their strategies accordingly.",
  },
];
let tabsforContractors = [
  "One dashboard",
  "Zero tender fees",
  "Access vendors",
  "Real time tracking",
  "Get verified",
];

let tabsforUsers = [
  "Verified professionals",
  "Transparent bid process",
  "Project Clarity",
  "Secured Capital",
  "Real-time Tracking",
];

export {
  faqData,
  features,
  copies,
  copiesForContractor,
  tabsforContractors,
  tabsforUsers,
};
