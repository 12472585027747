import { FeaturesIcon, ResourceIcon } from "assets/icons";
import { FC, useState } from "react";
import { CardComponent, SectionBadge, FeatureItemCard } from "components";
import { features } from "pages/doctor/constant";
import { Feature5Image } from "assets/images";
import { ContactModal } from "./HeroSection";

export const FeaturesSection: FC = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  return (
    <>
      {modal && <ContactModal closer={toggleModal} />}

      <div className="bg-bgSecondary">
        <div className="py-8 md:py-16 xl:py-20 px-5 md:px-16 lg:px-16">
          <div className="flex flex-col justify-center items-center  referral-container gap-8">
            <SectionBadge title="Features" icon={FeaturesIcon} isWhiteColor />
            <div className="flex flex-col gap-6 lg:w-[740px] text-center">
              <p className="font-bold font-SatoshiBold text-5xl text-ashShade-10">
                Here is why you should use Bnkle
              </p>
              <p className="font-SatoshiMedium text-xl text-bash">
                You can finally access the tools and resources that help you
                deliver a project seamlessly. All in one place.
              </p>
            </div>
            <div className="grid lg:grid-cols-2 gap-8">
              {features.map((feature, index) => (
                <FeatureItemCard
                  key={index}
                  badgeTitle={feature.badgeTitle}
                  badgeIcon={feature.badgeIcon}
                  cardTitle={feature.cardTitle}
                  cardBody={feature.cardBody}
                  image={feature.image}
                  isWhiteColor={feature.isWhiteColor}
                  isDeepBlueColor={feature.isDeepBlueColor}
                  largeScreenButtonText="Book Demo"
                  onClick={toggleModal}
                />
              ))}
            </div>
            <div className="flex flex-col lg:flex-row justify-between lg:h-[480px] gap-y-4 sm:gap-x-8 rounded-3xl pt-12 lg:pb-12 bg-white w-full">
              <CardComponent
                badgeTitle="Manage resources"
                badgeIcon={ResourceIcon}
                cardTitle="Inventory Management"
                cardBody="Track material procurement and usage to eliminate theft and waste"
                largeScreenButtonText="Book a demo"
                isWhiteColor
                onClick={toggleModal}
                showBadgeForLargeScreen
              />
              <div className=" w-full pl-4 lg:w-[80%]  h-full lg:h-[416px] flex justify-end  ">
                <img
                  src={Feature5Image}
                  alt=""
                  className=" w-full lg:w-auto h-full  lg:object-contain self-end"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
