import { Person } from "../constants";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const PersonaCard = ({
	colorScheme,
	image,
	persona,
	description,
	link,
}: Person) => {
	const color =
		colorScheme === "blue"
			? "bg-bblue"
			: colorScheme === "orange"
			? "bg-borange"
			: "bg-bgreen-3";
	const textBg =
		colorScheme === "blue"
			? "bg-landingblue"
			: colorScheme === "orange"
			? "bg-[#331C0A]"
			: "bg-[#082219]";

	return (
		<div className="flex flex-col h-full relative">
			<div className="relative  w-full bg-red">
				<img
					src={image}
					alt={persona}
					className="relative z-10 w-full px-6 h-[380px] object-cover"
				/>
				<div
					className={`h-[85%] ${color} absolute bottom-0 w-full rounded-t-2xl`}
				></div>
			</div>
			<div
				className={`${textBg} flex flex-col justify-between rounded-b-2xl relative py-6 px-4 md:p-6 h-full`}
			>
				<div>
					<h3 className="text-xl xl:text-2xl font-SatoshiBold pb-4">
						{persona}
					</h3>
					<p className="text-bidsbg xl:text-lg pb-4">{description}</p>
				</div>
				<Link to={link}>
					<div className="flex gap-4 items-center">
						<span className="font-semibold font-inter">Learn more</span>
						<FaLongArrowAltRight size={24} />
					</div>
				</Link>
			</div>
		</div>
	);
};
export default PersonaCard;
