import React from "react";
import PolicyContent from "./PolicyContent";

const PrivacyContent = () => {
	return (
		<section className="content-section bg-white px-6 pt-20 pb-12 lg:pb-16 lg:pt-28">
			<div className="max-w-4xl mx-auto text-gray-800">
				<h2 className="text-2xl font-SatoshiBold mb-4">1. Introduction</h2>
				<PolicyContent
					title="1.1 Overview"
					content="Welcome to Bnnkle, a marketplace for technologists. This Privacy Policy outlines how we collect, use, and protect your information when you use our website, web application, and related services (collectively, the “Services”). By accessing or using our Services, you agree to the terms of this Privacy Policy."
				/>
				<PolicyContent
					title="1.2 Acceptance of Policy"
					content="By using our Services, you acknowledge that you have read and understood this Privacy Policy. If you do not agree with the terms outlined herein, please refrain from using our Services."
				/>
				<h2 className="text-2xl font-SatoshiBold mb-4">
					2. Information We Collect
				</h2>
				<PolicyContent
					title="2.1 Personal Information"
					content="We collect various types of personal information, including but not limited to your name, email address, contact information, and other identifiers when you register for an account, submit forms, or interact with our Services.
 "
				/>
				<PolicyContent
					title="2.2 Usage Information"
					content="We gather information about how you use our Services, including your IP address, device information, browser type, and pages visited. This helps us analyze trends, improve functionality, and enhance user experience."
				/>
				<PolicyContent
					title="2.3 Cookies and Tracking Technologies"
					content="We use cookies and similar technologies to enhance your experience, customize content, and analyze trends. You can manage cookie preferences through your browser settings."
				/>
				<PolicyContent
					title="2.4 Third-Party Data"
					content="We may receive information from third-party sources to enhance the accuracy of our records and improve our Services."
				/>
				<h2 className="text-2xl font-SatoshiBold mb-4">
					3. How We Use Your Information
				</h2>
				<PolicyContent
					title="3.1 Providing and Improving Services"
					content="We use the information we collect for various purposes, including providing and improving our Services, customizing content and user experience, communicating with you about updates and promotions, and analyzing usage patterns and trends."
				/>
				<PolicyContent
					title="3.2 Customization and Personalization"
					content="We strive to provide a personalized experience by using your information to tailor our Services to your preferences and interests."
				/>
				<PolicyContent
					title="3.3 Communication"
					content="We may use your contact information to communicate with you about our Services, promotions, and important updates. You can manage your communication preferences through your account settings."
				/>
				<PolicyContent
					title="3.4 Analytics and Insights"
					content="We analyze user behaviour and trends to gain insights into how our Services are used. This helps us enhance functionality and optimize user experience."
				/>
				<h2 className="text-2xl font-SatoshiBold mb-4">
					4. Information Sharing
				</h2>
				<PolicyContent
					title="4.1 With Your Consent"
					content="We do not sell, trade, or otherwise transfer your personal information to third parties without your consent."
				/>
				<PolicyContent
					title="4.2 Service Providers"
					content="We may share information with trusted third-party service providers who assist us in operating our Services, conducting our business, or servicing you."
				/>
				<PolicyContent
					title="4.3 Legal Requirements"
					content="We may disclose your information if required by law, governmental request, or where we believe it is necessary to protect our legal rights, the rights of others, or for the prevention or detection of a crime."
				/>
				<PolicyContent
					title="4.4 Business Transfers"
					content="In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction."
				/>
				 <h2 className="text-2xl font-SatoshiBold mb-4">5. Your Choices</h2>
				<PolicyContent
					title="5.1 Account Settings"
					content="You can manage your account settings, update preferences, and adjust privacy settings to control the information you share."
				/>
				<PolicyContent
					title="5.2 Communication Preferences"
					content="While we may send essential communications related to your account, you can opt out of promotional communications."
				/>
				<PolicyContent
					title="5.3 Cookie Management"
					content="You can manage cookie preferences through your browser settings. Please note that disabling certain cookies may affect the functionality of our Services."
				/>
				<PolicyContent
					title="5.4 Data Access and Deletion Requests"
					content="You have the right to access the information we hold about you and request its deletion. Please contact us at writeus@rivala.io for assistance."
				/>
				<h2 className="text-2xl font-SatoshiBold mb-4">6. Data Security</h2>
				<PolicyContent
					title="6.1 Security Measures"
					content="We employ industry-standard security measures to protect your data from unauthorized access, alteration, or disclosure."
				/>
				<PolicyContent
					title="6.2 Data Retention"
					content="We retain your information only as long as necessary for the purposes outlined in this Privacy Policy or as required by law."
				/>
				<PolicyContent
					title="6.3 Third-Party Security"
					content="While we take measures to protect your data, we cannot guarantee the security of information transmitted to or from our Services over the Internet."
				/>
				<h2 className="text-2xl font-SatoshiBold mb-4">
					7. Children’s Privacy
				</h2>
				<PolicyContent
					title="7.1 Age Restriction"
					content="Our Services are not intended for users under the age of 18. We do not knowingly collect or store information from children."
				/>
				<PolicyContent
					title="7.2 Parental Controls"
					content="Parents or legal guardians can contact us at writeus@rivala.io to inquire about and manage the information collected."
				/>
				<h2 className="text-2xl font-SatoshiBold mb-4">
					8. International Users
				</h2>
				<PolicyContent
					title="8.1 Cross-Border Data Transfer"
					content="By using our Services, you consent to the transfer of your information to countries outside your country of residence, including the United States, where data protection laws may differ."
				/>
				<PolicyContent
					title="8.2 Compliance with Laws"
					content="We comply with applicable data protection laws and regulations, and we expect our users to do the same."
				/>
				<h2 className="text-2xl font-SatoshiBold mb-4">
					9. Changes to this Privacy Policy
				</h2>
				<PolicyContent
					title="9.1 Notification of Changes"
					content="We may update this Privacy Policy to reflect changes in our practices or legal requirements. Significant changes will be communicated through our website or by other means."
				/>
				<PolicyContent
					title="9.2 Effective Date"
					content="The date of the latest revision will be indicated at the top of the policy."
				/>
				<h2 className="text-2xl font-SatoshiBold mb-4">10. Contact Us</h2>
				<PolicyContent
					title="10.1 General Inquiries"
					content="For general inquiries regarding this Privacy Policy, please contact us at writeus@bnkle.io."
				/>
				<PolicyContent
					title="10.2 Data Protection Officer"
					content="If you have specific concerns about data protection, you can contact our Data Protection Officer at writeus@bnkle.io"
				/>
				     
			</div>
		</section>
	);
};

export default PrivacyContent;
