import React from "react";

interface PolicyContentProps {
	title: string;
	content: string;
}

const PolicyContent: React.FC<PolicyContentProps> = ({ title, content }) => {
	return (
		<>
			<h3 className="font-SatoshiMedium my-6">{title}</h3>
			<p className="mb-16 font-SatoshiMedium">{content}</p>
		</>
	);
};

export default PolicyContent;
