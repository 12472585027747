import { FC } from "react";
import { faqData } from "../constant";
import { CardComponent, FaqComponent } from "components";
import { FaqIcon } from "assets/icons";

export const FaqSection: FC = () => {
  return (
    <div className="bg-white">
      <div className="py-8 md:py-16 xl:py-20 px-5 md:px-16 lg:px-16">
        <div className="flex flex-col lg:flex-row lg:gap-20 lg:justify-between referral-container  ">
          <div>
            <CardComponent
              badgeTitle="FAQ'S"
              badgeIcon={FaqIcon}
              cardTitle="Frequently asked questions"
              cardBody="Everything you need to know about the product and billing."
              largeScreenButtonText="Ask a Question"
              isWhiteColor
              buttonBackgroundColor="#437ADB"
              buttonColor="#fff"
              linkTo={"../contact"}
              showPadding={false}
            />
          </div>
          <div className=" flex flex-col gap-6 mt-12">
            {faqData.map((faq, index) => (
              <FaqComponent
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
