import { useState } from "react";
import { CardComponent, SectionBadge, FeatureItemCard } from "components";
import ContactSalesModal from "components/newShared/ContactSalesModal";
import { FeaturesIcon } from "assets/icons";
import { features } from "./constants";
import featureImg5 from "assets/images/homefeature5.png";

const Features = () => {
	// const [modal, setModal] = useState(false);

	// const onClick = () => {
	// 	// setModal(!modal);
	// };

	return (
		<div className="bg-bgSecondary">
			<div className="py-8 md:py-16 xl:py-20 px-5 md:px-16 lg:px-16">
				<div className="flex flex-col justify-center items-center  referral-container gap-8">
					<SectionBadge title="Features" icon={FeaturesIcon} isWhiteColor />
					<div className="flex flex-col gap-6 lg:w-[740px] text-center">
						<p className="font-bold font-SatoshiBold text-5xl text-ashShade-10">
							Here is why you should use Bnkle
						</p>
						<p className="font-SatoshiMedium text-xl text-bash">
							You can finally access the tools and resources that help you
							deliver a project seamlessly. All in one place.
						</p>
					</div>
					<div className="grid lg:grid-cols-2 gap-8">
						{features.map((feature, index) => (
							<FeatureItemCard
								key={index}
								cardTitle={feature.cardTitle}
								cardBody={feature.cardBody}
								image={feature.image}
								isWhiteColor={feature.isWhiteColor}
								isDeepBlueColor={feature.isDeepBlueColor}
								largeScreenButtonText="Learn More"
								smallScreenButtonText="Learn More"
								// onClick={onClick}
							/>
						))}
					</div>
					<div className="flex flex-col lg:flex-row justify-between gap-8 rounded-3xl pt-12 bg-white w-full">
						<CardComponent
							cardTitle="Eliminate the risk of financial loss."
							cardBody="With bnkle, your project funds are 100% insured. "
							largeScreenButtonText="Learn More"
							isWhiteColor
							// onClick={onClick}
						/>
						<div className="relative flex lg:pb-12 self-end w-[80%] lg:w-auto  ">
							<div className="">
								<img src={featureImg5} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* {modal && (
				<div
					onClick={() => {
						setModal(!modal);
					}}
					className="fixed   w-screen z-[999] h-screen top-0 left-0 flex items-center justify-center bg-black bg-opacity-60"
				>
					<div
						onClick={(e) => e.stopPropagation()}
						className=" max-w-[432px] p-4 lg:p-6 rounded-md bg-white w-4/5 md:w-2/3 lg:w-1/2 "
					>
						<ContactSalesModal closer={() => setModal(false)} />
					</div>
				</div>
			)} */}
		</div>
	);
};
export default Features;
