import React, { useState } from "react";
import { FC } from "react";
import ComparePlans from "./ComparePlans";
import PricingPlans from "./Plan";
import CompareAnuallyPlans from "../annually/CompareAnnualPlan";
import { allt, compareItems, fftt, fttt } from "../annually/Annually";

const Monthly: FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const plans = [
    {
      title: "Free",
      type: "",
      amount: "NGN 0",
      user: "Per 1 user",
      benefits: ["1 Project", "Storage 100MB"],
      buttonTitle: "Contact Us",
      free: "",
      hasDropdown: false,
      baseAmount: 0,
    },
    {
      title: "Basic",
      type: "",
      amount: "NGN 20,000",
      user: "Per user/ project",
      benefits: ["Unlimited projects", "Storage 1GB"],
      free: "All in Free +",
      buttonTitle: "Contact Us",
      hasDropdown: true,
      baseAmount: 20000,
    },
    {
      title: "Pro",
      amount: "NGN 45,000",
      type: "",
      user: "Per project",
      benefits: ["Unlimited projects", "Storage 10GB"],
      free: "All in Basic +",
      buttonTitle: "Contact Us",
      hasDropdown: true,
      baseAmount: 45000,
    },
    {
      title: "Enterprise",
      type: "Contact Us",
      amount: "",
      user: "",
      free: "All in Pro +",
      buttonTitle: "Contact Us",
      benefits: ["Unlimited members", "Unlimited projects", "Storage 100GB"],
      hasDropdown: false,
      baseAmount: 0,
    },
  ];


  return (
    <div className="container mx-auto p-6">
      {/* Pricing Cards */}
      <PricingPlans
        plans={plans}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />

      {/* Compare Plans Table */}
      <ComparePlans
        plans={plans}
        compareItems={compareItems}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
    </div>
  );
};

export default Monthly;
