import projectOwnerImg from "../../assets/images/projectowner.png";
import developerImg from "../../assets/images/developer.png";
import contractorImg from "../../assets/images/contractor.png";
import projectImg1 from "../../assets/images/liveproject1.png";
import projectImg2 from "../../assets/images/liveproject2.png";
import projectImg3 from "../../assets/images/liveproject3.png";
import projectImg4 from "../../assets/images/liveproject4.png";
import projectImgMobile1 from "../../assets/images/liveprojectmobile1.png";
import projectImgMobile2 from "../../assets/images/liveprojectmobile2.png";
import projectImgMobile3 from "../../assets/images/liveprojectmobile3.png";
import projectImgMobile4 from "../../assets/images/liveprojectmobile4.png";
import featureImg1 from "../../assets/images/homefeature1.png";
import featureImg2 from "../../assets/images/homefeature2.png";
import featureImg3 from "../../assets/images/homefeature3.png";
import featureImg4 from "../../assets/images/homefeature4.png";

export type Person = {
  id: number;
  colorScheme: "blue" | "green" | "orange";
  image: string;
  persona: string;
  description: string;
  link: string;
};

export const personas: Person[] = [
  {
    id: 1,
    colorScheme: "blue",
    image: projectOwnerImg,
    persona: "Project Owner",
    description:
      "Get end-to-end project procurement that optimizes speed and quality at the right price.",
    link: "/owner",
  },
  {
    id: 2,
    colorScheme: "orange",
    image: developerImg,
    persona: "Developer",
    description: "Manage a portfolio of projects from one dashboard ",
    link: "/enterprise",
  },
  {
    id: 3,
    colorScheme: "green",
    image: contractorImg,
    persona: "Contractor",
    description:
      "Gain access to bid on an endless pool of new projects at the speed of a click",
    link: "/contractor",
  },
];

export const liveProjects = [
  {
    id: 1,
    name: "azalea apartments",
    location: "kigali, rwanda",
    imgMobile: projectImgMobile1,
    img: projectImg1,
  },
  {
    id: 2,
    name: "private villa",
    location: "abuja, nigeria",
    imgMobile: projectImgMobile2,
    img: projectImg2,
  },
  {
    id: 3,
    name: "lakeview apartments",
    location: "abuja, nigeria",
    imgMobile: projectImgMobile3,
    img: projectImg3,
  },
  {
    id: 4,
    name: "jasper heights",
    location: "abuja, nigeria",
    imgMobile: projectImgMobile4,
    img: projectImg4,
  },
];

export const features = [
  {
    cardTitle: "Project Tracking",
    cardBody: "Get real time comprehensive status reports on your project.",
    image: featureImg1,
  },
  {
    cardTitle: "Bid Management",
    cardBody:
      "Conduct online competitive bidding in a wide pool of verified contractors and consultants.",
    image: featureImg2,
    isWhiteColor: true,
  },
  {
    cardTitle: "Inventory Management",
    cardBody:
      "Track material procurement and usage to eliminate theft and waste",
    image: featureImg3,
    isWhiteColor: true,
  },
  {
    cardTitle: "Cost Control",
    cardBody:
      "Gain total visibility and control of project budget and expenses.",
    image: featureImg4,
    isDeepBlueColor: true,
  },
];
