import { useState } from "react";
import threeguys from "assets/threeguys.svg";
import { GrClose } from "react-icons/gr";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toast";

export type data = {
  firstname: string;
  lastname: string;
  email: string;
  message: string;
};

var url = "https://sheet2api.com/v1/NDjDSZa8QwW4/bnklecrm/Sheet1";
let schema = Yup.object({
  firstname: Yup.string()
    .min(2, "firstname cannot be less than two characters")
    .required("please input firstname"),
  lastname: Yup.string()
    .min(2, "lastname cannot be less than two characters")
    .required("please input lastname"),
  email: Yup.string()
    .email("please enter a valid email")
    .required("please input email"),
  message: Yup.string()
    .min(7, "message cannot be less than seven characters")
    .required("please leave a message for us"),
});

const GetInTouch = () => {
  const [submitting, setSubmitting] = useState(false);
  const [intouch, setIntouch] = useState(false);

  let { pathname } = useLocation();
  let show = ["enterprise", "consultant", "contractor"].includes(
    pathname.split("/")[1]
  )
    ? false
    : true;
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      message: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await submitForm(values);
    },
  });

  let navigate = useNavigate();
  const submitForm = async (data: data) => {
    setSubmitting(true);
    try {
      let res = await axios.post(url, {
        ...data,
        timestamp: new Date().toISOString(),
      });

      if (res.status < 300) {
        toast("Thank you for your message. We will reach out soon", {
          backgroundColor: "blue",
          color: "#fff",
        });
        formik.resetForm();
      } else {
        toast("We did not get your message. Please try again", {
          backgroundColor: "blue",
          color: "#fff",
        });
      }
    } catch (error) {
      toast("We did not get your message. Please try again", {
        backgroundColor: "blue",
        color: "#fff",
      });
    }
    setSubmitting(false);
  };
  return (
    <div className="px-5 md:px-16 pb-20 bg-white">
      <div className="relative  bg-lightblue flex  xl:max-w-[1234px]  mx-auto flex-col items-center rounded-3xl md:py-16 px-3 w-full py-4">
        {intouch && (
          <span
            onClick={() => setIntouch(false)}
            className="bg-white hover:cursor-pointer absolute top-8 right-8 p-2 rounded-full"
          >
            <GrClose size={16} />
          </span>
        )}
        <img src={threeguys} alt="three guys" />
        <h2 className="text-bblack-1 mt-5 text:xl sm:text-2xl md:text-4xl font-DemiBold text-center w-full ">
          Still have questions?
        </h2>
        <p className="mt-2 mb-8 text-base md:text-xl text-bash text-center">
          Can’t find the answers you’re looking for? Please send us a message
        </p>
        {intouch ? (
          <div className="m-auto max-w-[480px]">
            <div className="w-full flex ">
              <label
                className="text-bblack-0 mt-2 mr-3 flex flex-col"
                htmlFor="firstname"
              >
                First name
                <input
                  className="w-full py-3 px-4 mt-2  outline-bblue  rounded-md bg-white text-bash"
                  type="text"
                  name="firstname"
                  placeholder="First name"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                />
                {formik.errors.firstname && (
                  <p className="text-red text-sm text-red-500">
                    {formik.errors.firstname}
                  </p>
                )}
              </label>
              <label
                className="text-bblack-0 mb-6 mt-2 flex flex-col"
                htmlFor="lastname"
              >
                Last name
                <input
                  className="w-full py-3 px-4 mt-2 outline-bblue  rounded-md bg-white text-bash"
                  type="text"
                  name="lastname"
                  placeholder="Last name"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                />
                {formik.errors.lastname && (
                  <p className="text-red text-sm text-red-500">
                    {formik.errors.lastname}
                  </p>
                )}
              </label>
            </div>
            <label
              className="text-bblack-0 mb-6 mt-2 flex flex-col "
              htmlFor="email"
            >
              Email
              <input
                className="w-full py-3 px-4  mt-2 outline-bblue  rounded-md bg-white text-bash"
                type="text"
                name="email"
                placeholder="you@company.com"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email && (
                <p className="text-red text-sm text-red-500">
                  {formik.errors.email}
                </p>
              )}
            </label>
            <label className="text-bblack-0  mb-6 mt-6" htmlFor="message">
              Message
              <textarea
                className="w-full h-36 py-3 px-4 mt-2 outline-bblue  rounded-md bg-white text-bash"
                name="message"
                placeholder="Leave us a message..."
                value={formik.values.message}
                onChange={formik.handleChange}
              />
              {formik.errors.message && (
                <p className="text-red text-sm text-red-500">
                  {formik.errors.message}
                </p>
              )}
            </label>
            <button
              onClick={(e) => formik.handleSubmit()}
              className="bg-bblue text-white py-3 mt-6 flex justify-center  px-8 rounded-md w-full hover:bg-blueShades-1 active:bg-blueShades-0"
            >
              {submitting ? (
                <div className="w-6 h-6 border-4  rounded-full border-t-white animate-spin border-x-slate-50 border-b-transparent   "></div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        ) : (
          <button
            onClick={() => navigate("/contact")}
            className="bg-bblue text-white py-3 px-8 rounded-md hover:bg-blueShades-1 active:bg-blueShades-0 w-full md:w-auto"
          >
            Get in touch
          </button>
        )}
      </div>
    </div>
  );
};
export default GetInTouch;
