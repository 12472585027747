import React, { useState } from "react";
import { FC } from "react";
import CompareAnuallyPlans from "./CompareAnnualPlan";
import AnnualPricingPlans from "./AnnualPlan";

export const fftt = [false, false, true, true];
export const allt = [true, true, true, true];
export const ftft = [false, true, false, true];
export const fttt = [false, true, true, true];
export const ffft = [false, false, false, true];

const Anually: FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null); // Track the selected plan

  // Example data for the pricing cards and comparison table
  const plans = [
    {
      title: "Free",
      amount: "NGN 0",
      user: "Per 1 user",
      type: "",
      benefits: ["1 Project", "Storage 100MB"],
      buttonTitle: "Contact Us",
      free: "",
      hasDropdown: false,
      baseAmount: 0,
    },
    {
      title: "Basic",
      amount: "NGN 20,000",
      user: "Per user/ project",
      type: "",
      benefits: ["Unlimited projects", "Storage 1GB"],
      free: "All in Free +",
      buttonTitle: "Contact Us",
      hasDropdown: true,
      baseAmount: 20000,
    },
    {
      title: "Pro",
      type: "",
      amount: "NGN 45,000",
      user: "Per project",
      benefits: ["Unlimited projects", "Storage 10GB"],
      free: "All in Basic +",
      buttonTitle: "Contact Us",
      hasDropdown: true,
      baseAmount: 45000,
    },
    {
      title: "Enterprise",
      type: "Contact Us",
      amount: "",
      user: "",
      free: "All in Pro +",
      buttonTitle: "Contact Us",
      benefits: ["Unlimited members", "Unlimited projects", "Storage 100GB"],
      hasDropdown: false,
      baseAmount: 0,
    },
  ];

  return (
    <div className="container mx-auto p-6">
      <AnnualPricingPlans
        plans={plans}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />

      <CompareAnuallyPlans
        plans={plans}
        compareItems={compareItems}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
    </div>
  );
};

export const compareItems = [
  {
    feature: "",
    subFeatures: [
      {
        name: "Dashboard",
        availability: allt,
      },
      {
        name: "Cluster",
        availability: fttt,
      },
    ],
  },
  {
    feature: "Communication",
    subFeatures: [
      {
        name: "Messaging Team Members",
        availability: fttt,
      },
      {
        name: "Task based messaging",
        availability: fftt,
      },
      {
        name: "Group based messaging",
        availability: fftt,
      },
      {
        name: "Scheduled meeting",
        availability: allt,
      },
      {
        name: "site instructions",
        availability: fttt,
      },
    ],
  },
  {
    feature: "Scheduling",
    subFeatures: [
      {
        name: "Create work programs",
        availability: allt,
      },
      {
        name: "Work programme template",
        availability: fftt,
      },
      {
        name: "Grant Chart",
        availability: allt,
      },
      {
        name: "Table View",
        availability: allt,
      },
      {
        name: "Kanban View",
        availability: allt,
      },
      {
        name: "Resource Control",
        availability: fttt,
      },
      {
        name: "Task tracking and notification",
        availability: allt,
      },
    ],
  },
  {
    feature: "Team",
    subFeatures: [
      {
        name: "Manage Team",
        availability: fttt,
      },
      {
        name: "Assign task",
        availability: fttt,
      },
      {
        name: "Assign roles",
        availability: fttt,
      },
    ],
  },
  {
    feature: "Procurement",
    subFeatures: [
      {
        name: "Bids",
        availability: fftt,
      },
      {
        name: "Inventory management",
        availability: fttt,
      },
    ],
  },
  {
    feature: "Finance",
    subFeatures: [
      {
        name: "Disbursement planning & tracker",
        availability: fttt,
      },
      {
        name: "Payment tracker",
        availability: fttt,
      },
      {
        name: "Invoicing",
        availability: fttt,
      },
      {
        name: "Expense Tracker",
        availability: fttt,
      },
    ],
  },
  {
    feature: "Documents",
    subFeatures: [
      {
        name: "File & Folder management",
        availability: allt,
      },
      {
        name: "Access control",
        availability: fttt,
      },
    ],
  },
  {
    feature: "Photos & Videos",
    subFeatures: [
      {
        name: "",
        availability: allt,
      },
    ],
  },
  {
    feature: "Payment",
    subFeatures: [
      {
        name: "Payment frequency",
        availability: [" ", "Monthly/Annually", "Monthly/Anually", "Annual"],
      },
      {
        name: "Payment type",
        availability: ["", "Card", "Card", "Card / Invoice / Check / Transfer"],
      },
    ],
  },
  {
    feature: "Notification",
    subFeatures: [
      {
        name: "Email notification",
        availability: fttt,
      },
      {
        name: "In-app notification",
        availability: allt,
      },
    ],
  },
  {
    feature: "Support",
    subFeatures: [
      {
        name: "Feature Request Priority",
        availability: [false, true, true, false],
      },
      {
        name: "In App/ Email support",
        availability: fttt,
      },
      {
        name: "Slack support",
        availability: allt,
      },
      {
        name: "Dedicated Slack Support/Support Manager",
        availability: ffft,
      },
    ],
  },
  // {
  //   feature: "Security",
  //   subFeatures: [
  //     {
  //       name: "Fine grained permission control",
  //       availability: fttt,
  //     },
  //     {
  //       name: "2FA",
  //       availability: allt,
  //     },
  //     {
  //       name: "On prem hosting",
  //       availability: ["", "", "", "Coming Soon"],
  //     },
  //   ],
  // },
];

export default Anually;
