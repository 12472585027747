import { SmallPMHeroImage } from "assets/images";
import { LargePMHeroImage } from "assets/svg";
import { Subtitle, Title } from "components";
import Button from "components/shared/Button";
import { FC } from "react";
import { Link } from "react-router-dom";

export const HeroSection: FC = () => {
  return (
    <div className="bg-project-manager bg-cover">
      <div className="pt-8 md:pt-16 xl:pt-20 px-5 md:px-16 lg:px-16">
        <div className="h-full w-full flex flex-col justify-center lg:items-center gap-8">
          <div className="lg:hidden w-full border-8 border-solid border-bblack-4 rounded-2xl">
            <img
              src={SmallPMHeroImage}
              alt="Project Manager Hero"
              className="rounded-lg w-full"
            />
          </div>
          <div className="flex flex-col gap-6 lg:w-[689px] text-center referral-container">
            <p className="font-SatoshiBold  text-bblack-1 text-[2.5rem] lg:text-7xl">
              Bnkle: Your On-Call Project Manager
            </p>
            <p className="font-SatoshiMedium  text-bash text-xl">
              Use verified, high quality professionals, conduct online bids,
              procure materials and get real time progress reports from anywhere
              in the world.
            </p>
          </div>
          <Link
            to={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup`}
            className="flex flex-col pb-12 lg:pb-0"
          >
            <Button label="Start here" />
          </Link>
          <div className="hidden lg:block w-[60%] 3xl:w-[50%] m-auto">
            <img
              src={LargePMHeroImage}
              alt="Project Manager Hero"
              className="w-full m-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
