import React, { useState } from "react";

const ProgressCard = () => {
  const lowerLimit = 5000000;
  const upperLimit = 500000000;
  const incrementPerPx = 1000000;

  const [progress, setProgress] = useState(0);

  const totalRange = upperLimit - lowerLimit;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const startX = e.clientX;
    const progressBar = e.currentTarget.parentElement!;
    const progressBarWidth = progressBar.offsetWidth;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      const deltaX = moveEvent.clientX - startX;
      let newProgress = progress + (deltaX / progressBarWidth) * 100;

      // Limit progress between 0 and 100
      newProgress = Math.max(0, Math.min(100, newProgress));
      setProgress(newProgress);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const rawProjectCost = lowerLimit + (totalRange * progress) / 100;

  const projectCost =
    Math.round(rawProjectCost / incrementPerPx) * incrementPerPx;

  const fees = Math.round(0.075 * projectCost);

  return (
    <div className="lg:mx-auto mx-4 mb-14 p-6 mt-6 border border-[#F1F2F4] rounded-xl shadow-xl lg:w-full lg:max-w-2xl">
      <div className="relative w-full h-2 bg-[#437ADB] rounded-full mb-20 mt-6">
        <div
          className="absolute right-0 h-full bg-[#C1C2C4] rounded-full"
          style={{ width: `${100 - progress}%` }}
        ></div>

        <div
          className="absolute top-1/2 transform -translate-y-1/2 bg-[#fff] border-2 border-[#437ADB] w-6 h-6 rounded-full cursor-pointer"
          style={{ left: `calc(${progress}% - 12px)` }}
          onMouseDown={handleMouseDown}
        ></div>
      </div>

      {/* Cards */}
      <div className="flex flex-col md:flex-row justify-start gap-8">
        <div className="w-full bg-[#F4F4F5] p-4 rounded-lg">
          <p className="text-sm font-SatoshiRegular mb-6">Project Cost</p>
          <span className="text-2xl font-SatoshiBold">
            NGN {projectCost.toLocaleString()}
          </span>
        </div>

        <div className="w-full bg-[#F4F4F5] p-4 rounded-lg">
          <p className="text-sm font-SatoshiRegular mb-6">Fees</p>
          <span className="text-2xl font-SatoshiBold">
            NGN {fees.toLocaleString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProgressCard;
