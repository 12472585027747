import React from "react";
import PrivacyContent from "./PrivacyContent";

const Privacy = () => {
	return (
		<div className="privacy-policy-page">
			<section className="hero-section bg-[#ECF2FB] flex items-center justify-center py-32">
				<h1 className="text-6xl font-SatoshiMedium text-center">
					Privacy Policy
				</h1>
			</section>
			<PrivacyContent />
		</div>
	);
};

export default Privacy;
