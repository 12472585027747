import Button from "components/shared/Button";
import { FC } from "react";

import { Link } from "react-router-dom";

export const PremiumHeroSection: FC = () => {
  return (
    <>
      <div className="relative w-full ">
        <div className="write-up  top-0 left-0 right-0 px-4 md:px-0 mt-8 md:mt-2 xl:mt-6 3xl:mt-28 4xl:mt-28 mb-10 flex flex-col mx-auto max-w-full md:max-w-[700px] xl:max-w-[1000px] text-center">
          <span className="font-SatoshiBold text-[2rem] md:text-[2.5rem] xl:text-[3.8rem] 3xl:text-[5rem] 4xl:text-[5.5rem] leading-tight">
            <span className="block">
              Estimate how much Bnkle Charges in Fees for Delivering your
              project
            </span>
            {/* <span className="max-w-full md:max-w-[60%] xl:max-w-[60%] mx-auto">
							with the Premium plan.
						</span> */}
          </span>
        </div>

        <div className="cards"></div>
      </div>
      <div className="flex justify-center self-center mt-1 xl:my-20 md:mt-8  3xl:mt-20">
        <Link
          to={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup`}
          // className="flex flex-col pb-12 lg:pb-0"
        >
          <Button
            label="Get Started"
            className="text-sm md:text-sm 3xl:text-6xl md:bottom-4 font-SatoshiBold rounded-md xl:text-4xl h-9 md:h-9 xl:h-12 3xl:h-16 "
          />
        </Link>
      </div>
    </>
  );
};
