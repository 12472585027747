import React from "react";
import { Cards } from "./Cards";

const whatYouGet = () => {
	return (
		<div
			className=" px-40 py-20 bg-#F4F4F5"
			style={{ backgroundColor: "#F4F4F5" }}
		>
			<h1 className="text-5xl mb-16 font-SatoshiMedium text-[#101828]">
				What you get
			</h1>
			<Cards />
		</div>
	);
};

export default whatYouGet;
