import { useEffect } from "react";
import Tag from "components/newShared/Tag";
import { HiOutlinePhone } from "react-icons/hi";
import faqIcon from "assets/svg/message-question.svg";
import contactImg from "assets/images/contactwomen.png";
import { questions } from "./constants";
import Header from "components/newShared/Header";
import Footer from "components/newShared/Footer";
import ContactInfo from "./ContactInfo";
import ContactForm from "./ContactForm";
import Faq from "components/newShared/Faq";
import { FaqSection } from "components";

const ContactUs = () => {


  return (
    <div id="contact" className="w-full font-SatoshiRegular">
      <Header
        signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
        signUpLink={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup?fromreferralPage=true`}
      />
      <div className="bg-bgSecondary">
        <div className="py-8 md:py-16 xl:py-20 px-5 md:px-16 lg:px-16">
          <div className="flex flex-col lg:flex-row justify-between referral-container gap-10 lg:gap-0 lg:items-center xl:items-start">
            <div className="flex flex-col gap-10 xl:gap-14 w-full lg:w-5/12">
              <div>
                <Tag title="Contact us" icon={<HiOutlinePhone size={16} />} />
                <div className="text-bblack-1 pt-3">
                  <h3 className="font-SatoshiBold text-2xl xl:text-3xl pb-[3px]">
                    Have any questions? Contact us{" "}
                  </h3>
                  <p className="leading-6 font-medium">
                    Reach out to us for inquires, support, or partnership
                    opportunities.
                    <br />
                    We're here to assist you!
                  </p>
                </div>
              </div>
              <ContactInfo />
            </div>
            <ContactForm />
          </div>
        </div>
      </div>
      <FaqSection />
      {/* <div className="py-8 md:py-16 xl:py-20 px-5 md:px-16">
        <div className="referral-container">
          <div className="flex flex-col gap-6 w-full">
            <div className="w-full lg:w-5/12">
              <Tag title="FAQ'S" src={faqIcon} />
              <div className="text-bblack-1 pt-3">
                <h3 className="font-SatoshiBold text-2xl xl:text-3xl pb-[3px]">
                  Frequently asked questions
                </h3>
                <p className="leading-6 font-medium text-bash">
                  Everything you need to know about the product and billing.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between w-full gap-10 lg:gap-0">
              <div className="lg:w-5/12">
                <img src={contactImg} alt="contact us" className="w-full" />
              </div>
              <div className="flex flex-col gap-6 md:block w-full lg:w-6/12">
                {questions.map((item) => {
                  const { id, question, answer } = item;
                  return <Faq key={id} question={question} answer={answer} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default ContactUs;
