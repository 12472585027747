import Button from "components/shared/Button";
import { FC } from "react";
import house from "assets/project-house.png";
import house2 from "assets/house-pics.png";
import { Link } from "react-router-dom";

export const HeroSection: FC = () => {
	return (
		<div className="relative w-full bg-custom-gradient md:bg-transparent">
			<div className="relative mt-0 md:mt-0">
				<div className="hidden md:block">
					<img src={house} alt="house" className="w-full" />
				</div>
				<div className="block md:hidden ">
					<img src={house2} alt="house" className="w-full pt-52  xs:pt-80" />
				</div>
			</div>
			<div className="write-up absolute top-0 left-0 right-0 px-4 md:px-0 mt-8 md:mt-2 xl:mt-16 3xl:mt-28 4xl:mt-28 mb-10 flex flex-col mx-auto max-w-full md:max-w-[700px] xl:max-w-[1200px] text-center">
				<span className="font-SatoshiBold text-[2rem] md:text-[2.5rem] xl:text-[4.4rem] 3xl:text-[5rem] 4xl:text-[5.5rem] text-[#1B2F58] leading-tight">
					<span className="block">Build your dream project.</span>
					<span className="max-w-full md:max-w-[60%] xl:max-w-[60%] mx-auto">
						hassle free.
					</span>
				</span>
				<span className="access text-[#0D182C] font-SatoshiRegular mt-3 text-[18px] md:text-[16px] xl:text-xl 3xl:leading-normal 4xl:leading-normal 3xl:text-3xl 4xl:text-3xl max-w-full md:max-w-[500px] xl:max-w-[1180px] mx-auto xl:mt-7">
					Access vetted high quality professionals, conduct online bids, procure
					materials and get real time
					<span className="hidden xl:inline 4xl:hidden 3xl:hidden ">
						<br />
					</span>
					progress reports from anywhere in the world.
				</span>

				<div className="self-center mt-8  w-full px-0 block md:hidden ">
					<Link
						to={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup`}
						className="flex flex-col pb-12 lg:pb-0"
					>
						<Button
							label="Sign Up"
							className="text-sm md:text-sm md:bottom-4 font-SatoshiBold rounded-md xl:text-4xl block w-full h-10 md:h-8 xl:h-12"
						/>
					</Link>
				</div>
				<div className="self-center mt-1 xl:mt-10 md:mt-8 hidden md:block 3xl:mt-20">
					<Link
						to={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup`}
						className="flex flex-col pb-12 lg:pb-0"
					>
						<Button
							label="Sign Up"
							className="text-sm md:text-sm 3xl:text-6xl md:bottom-4 font-SatoshiBold rounded-md xl:text-4xl block w-full h-6 md:h-9 xl:h-12 3xl:h-16 3xl:w-48"
						/>
					</Link>
				</div>
			</div>
		</div>
	);
};
