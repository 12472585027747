import React from "react";
import Footer from "../../components/newShared/Footer";
import Header from "components/newShared/Header";
import HeroSection from "./components/HeroSection";
import { FeaturesSection } from "./components/FeaturesSection";
import { FaqSection } from "components/containers/FaqSection";
import { EstimateSection } from "components";

interface Prop {
  isConsultant?: boolean;
}
const Contrator = ({ isConsultant }: Prop) => {
  React.useEffect(() => {
    document.title = "Contractor";
  }, []);

  return (
    <div className=" relative flex flex-col items-center justify-start">
      <div className="w-full flex relative items-center flex-col h-full">
        <Header
          isBgWhite
          signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
          signUpLink={`https://auth.${process.env.REACT_APP_DOMAIN}`}
          signUpLabel="Sign up"
        />

        <div className="w-full border">
          <HeroSection />
          <FeaturesSection />
          <FaqSection />
          <EstimateSection />
          <div className="my-10" />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Contrator;
