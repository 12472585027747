import React, { useMemo } from "react";
import {
  general,
  contractorFaq,
  ownerfaq,
  enterpriseFaq,
} from "components/containers/constant";
import { useLocation } from "react-router-dom";

const useFaqs = () => {
  let { pathname } = useLocation();
  let faqs = {
    contractor: contractorFaq,
    owner: ownerfaq,
    general,
    enterprise: enterpriseFaq,
  };

  let questions = useMemo(() => {
    let dirs = pathname.split("/");
    let lastPath = dirs[dirs.length - 1];
    switch (lastPath) {
      case "owner":
        return faqs[lastPath];
      case "enterprise":
        return faqs[lastPath];
      case "contractor":
        return faqs[lastPath];
      case "consultant":
        return faqs["contractor"];
      default:
        return faqs["general"];
    }
  }, [pathname]);

  return questions;
};

export default useFaqs;
