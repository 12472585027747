import { FC } from "react";
import { EstimateSection, TestimonialSection } from "components";
import { HeroSection } from "./components/HeroSection";
import { FeaturesSection } from "./components/FeaturesSection";
import { FaqSection } from "components/containers/FaqSection";
import { NavBar } from "components/navbar/Navbar";
import Footer from "components/newShared/Footer";

export const OwnerPage: FC = () => {
	return (
		<>
			<HeroSection />
			<FeaturesSection />
			<TestimonialSection />
			<FaqSection />
			<EstimateSection />
		</>
	);
};
