import { FC } from "react";
import Privacy from "./components/Privacy";

export const PrivacyPage: FC = () => {
	return (
		<>
			<Privacy />
		</>
	);
};
