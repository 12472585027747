import React, { useEffect } from "react";
import ReactModal from "react-modal";
import ReactPlayer from "react-player";
import { IoCloseOutline } from "react-icons/io5";

ReactModal.setAppElement("#root");

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
	useEffect(() => {
		document.body.style.overflow = isOpen ? "hidden" : "auto";
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [isOpen]);

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onClose}
			contentLabel="Video Modal"
			overlayClassName="fixed inset-0 bg-black bg-opacity-80 z-50 flex justify-center items-center"
			className="relative bg-white max-w-2xl w-10/12 md:w-3/4 lg:w-3/4 xl:w-2/3 top-[15%] p-6 rounded-lg shadow-lg overflow-hidden"
		>
			<div className="flex flex-col items-center">
				<h2 className="text-xl font-semibold mb-2 text-center">
					Referral Video
				</h2>
				<button
					onClick={onClose}
					className="absolute top-4 right-4 w-8 h-8 flex items-center justify-center text-gray-600 hover:text-gray-800"
				>
					<IoCloseOutline size={25} />
				</button>
			</div>
			<div className="mt-4">
				<ReactPlayer
					url="https://www.youtube.com/watch?v=qoeqQ8GVBEw"
					controls
					width="100%"
					height="240px"
				/>
			</div>
		</ReactModal>
	);
};

export default Modal;
