import { FC } from "react";

interface Props {
  text: string;
  className?: string;
  textColor?: string;
}

export const Subtitle: FC<Props> = ({ text, className, textColor }) => {
  return (
    <>
      <p
        className={`lg:font-medium font-satoshi ${textColor ?? "text-bash"} text-xl text-justify lg:text-center ${className}`}
      >
        {text}
      </p>
    </>
  );
};
