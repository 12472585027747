import { liveProjects } from "./constants";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useState, useEffect } from "react";

const LiveProjects = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const itemsPerView = 1;
    const totalItems = liveProjects.length;

    const handleScroll = () => {
        if (currentIndex >= 1) {
            setIsScrolling(true);
        } else {
            setIsScrolling(false);
        }
    };

    const handleNext = () => {
        if (currentIndex < totalItems - itemsPerView) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    useEffect(() => {
        handleScroll();
    }, [currentIndex]);

    return (
        <section className='bg-bgSecondary py-8'>
            <div className='md:py-16 xl:py-20 px-5 md:px-16'>
                <div className='w-full referral-container'>
                    <div className='w-full'>
                        <div className='flex justify-between items-center'>
                            <div>
                                <div className='flex items-center gap-3'>
                                    <h2 className='font-SatoshiMedium text-2xl'>
                                        Live Projects
                                    </h2>
                                    <div className='w-4 h-4 bg-bgreen-0 rounded-full'></div>
                                </div>
                                <p className='pt-3 text-bash text-lg'>
                                    Build with confidence anywhere in Africa
                                </p>
                            </div>
                            <div className='hidden lg:flex gap-6'>
                                <button
                                    onClick={handlePrev}
                                    className='grid place-items-center border border-bblack-1 rounded-full w-8 h-8'
                                >
                                    <FiChevronLeft size={18} />
                                </button>
                                <button
                                    onClick={handleNext}
                                    className='grid place-items-center border border-bblack-1 rounded-full w-8 h-8'
                                >
                                    <FiChevronRight size={18} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full lg:hidden'>
                        <div className='flex flex-col gap-5 '>
                            {liveProjects.map((project) => {
                                const { id, name, location, imgMobile } =
                                    project;
                                return (
                                    <div key={id} className='relative w-full'>
                                        <img
                                            src={imgMobile}
                                            alt={name}
                                            className='w-full'
                                        />
                                        <span className='absolute bottom-8 left-6 z-5 text-white capitalize'>
                                            <p className='font-SatoshiMedium text-lg '>
                                                {name}
                                            </p>
                                            <p>{location}</p>
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={` w-full ${isScrolling ? "" : "mx-auto ml-auto mr-0 pl-16 live-projects "}`}
            >
                <div className='hidden lg:block w-full overflow-x-hidden'>
                    <div
                        className={`flex flex-grow-0 flex-shrink-0 gap-5 lg:transition-transform duration-500`}
                        style={{
                            transform: `translateX(-${currentIndex * 35}%)`,
                        }}
                    >
                        {liveProjects.map((project) => {
                            const { id, name, location, img } = project;
                            return (
                                <div
                                    key={id}
                                    className='relative lg:min-w-[45%]'
                                >
                                    <img
                                        src={img}
                                        alt={name}
                                        className='w-full h-full'
                                    />
                                    <span className='absolute bottom-12 left-6 z-5 text-white capitalize'>
                                        <p className='font-SatoshiMedium text-xl'>
                                            {name}
                                        </p>
                                        <p className='text-lg'>{location}</p>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};
export default LiveProjects;
