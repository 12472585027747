import React from "react";
import tick from "../../../assets/images/tick.png";
import dollar from "../../../assets/images/dollar.png";
import shield from "../../../assets/images/shield.png";
import building from "../../../assets/images/building.png";
import user from "../../../assets/images/user-tick.png";

const cardStyle = `
  @media (max-width: 375px) {
    .card {
      width: 90vw;
      margin: 0 auto;
    }
  }
  @media (max-width: 320px) {
    .card {
      width: 95vw;
      margin: 0 auto;
    }
  }
`;
const Card = ({
	imageSrc,
	title,
	content,
}: {
	imageSrc: string;
	title: string;
	content: string;
}) => {
	return (
		<div className="section">
			<div className="flex flex-col border rounded-2xl shadow-md p-4 bg-white w-full">
				<img src={imageSrc} alt={title} className="w-16 h-16 mb-2 ml-2" />
				<h3 className="font-SatoshiBold  mb-2 p-2 text-[#222B34]">{title}</h3>
				<p className=" text-sm leading-6 font-SatoshiMedium text-[#77828D] px-2">
					{content}
				</p>
			</div>
		</div>
	);
};

export const Cards = () => {
	const cardData = [
		{
			imageSrc: dollar,
			title: "Eliminate the risk of financial loss.",
			content: "With bnkle, your project funds are 100% insured.",
		},
		{
			imageSrc: shield,
			title: "Cost Control",
			content:
				"Gain total visibility and control of project budget and expenses.",
		},
		{
			imageSrc: building,
			title: "Gain clarity and control of your Project",
			content: "Don’t miss a thing, track project progress in real time.",
		},
		{
			imageSrc: user,
			title: "Choose from Verified Contractors and Consultants",
			content:
				"Access our curated pool of vetted contractors and consultants. Our rating system ensures that you find the perfect match for your project requirements.",
		},
		{
			imageSrc: tick,
			title: "Receive and Evaluate Bids Online",
			content:
				"Conduct competitive bids online to give you best value for money. Automate the tender process from issuing invitations to bid, to receiving and analyzing bids.",
		},
	];

	return (
		<>
			<style>{cardStyle}</style>
			<div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
				{cardData.map((card, index) => (
					<Card
						key={index}
						imageSrc={card.imageSrc}
						title={card.title}
						content={card.content}
					/>
				))}
			</div>
		</>
	);
};
