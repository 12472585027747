import { CardComponent } from "components";
import { FC } from "react";

interface Props {
  badgeTitle?: string;
  badgeIcon?: string;
  cardTitle: string;
  cardBody: string;
  image: string;
  isWhiteColor?: boolean;
  isDeepBlueColor?: boolean;
  largeScreenButtonText: string;
  smallScreenButtonText?: string;
  linkTo?: string;
  onClick?: () => void;
}

export const FeatureItemCard: FC<Props> = ({
  badgeTitle,
  badgeIcon,
  cardTitle,
  cardBody,
  image,
  isWhiteColor,
  isDeepBlueColor,
  smallScreenButtonText,
  largeScreenButtonText,
  linkTo,
  onClick,
}) => {
  return (
    <div
      className={`flex flex-col gap-8 md:items-baseline lg:h-[480px] rounded-xl lg:rounded-3xl overflow-hidden pt-12  ${isWhiteColor ? "bg-white" : isDeepBlueColor ? "bg-PRIMARY2" : "bg-PRIMARY"}`}
    >
      <CardComponent
        badgeTitle={badgeTitle}
        badgeIcon={badgeIcon}
        cardTitle={cardTitle}
        cardBody={cardBody}
        isWhiteColor={isWhiteColor}
        largeScreenButtonText={largeScreenButtonText}
        smallScreenButtonText={smallScreenButtonText}
        linkTo={linkTo}
        onClick={onClick}
        showBadgeForLargeScreen
      />
      <div className="self-end w-[80%] h-full">
        <img
          src={image}
          alt=""
          className="w-full h-full rounded-br-xl lg:rounded-br-3xl"
        />
      </div>
    </div>
  );
};
