import { Outlet, useLocation } from "react-router-dom";
import Footer from "components/newShared/Footer";
import { NavBar } from "./navbar/Navbar";
import Header from "./newShared/Header";
import { useMemo } from "react";

export const Layout = () => {
  let { pathname } = useLocation();

  let { signUpLabel, signUpLink, removeContactUs } = useMemo(() => {
    let isEnterprise = pathname === "/enterprise";
    let signUpLabel = isEnterprise ? `Contact Us` : undefined;
    let signUpLink = isEnterprise ? "/contact" : undefined;
    let removeContactUs = isEnterprise;

    return {
      signUpLabel,
      signUpLink,
      removeContactUs,
    };
  }, []);

  return (
    <div className="w-full font-SatoshiRegular">
      <Header
        removeContactUs={removeContactUs}
        signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
        signUpLink={
          signUpLink ??
          `https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup?fromreferralPage=true`
        }
        signUpLabel={signUpLabel}
      />
      <Outlet />
      <Footer />
    </div>
  );
};
