import Button from "components/shared/Button";
import construction from "../../../assets/images/construction-worker-truss-installation 1.png";

const HeroSection = () => {
  return (
    <div className=" w-full h-screen lg:h-full bg-bblue ">
      <div className="relative h-full w-full flex items-center lg:justify-between justify-center lg:max-w-[calc(100vw-((100vw-1234px)/2))] lg:ml-auto lg:mr-0 2xl:max-w-[1234px] 2xl:mx-auto">
        <div className="absolute inset-0 flex lg:hidden">
          <img
            src={construction}
            alt="Construction Image"
            className="w-full h-full object-cover opacity-20"
          />
        </div>

        <div className="bg:transparent lg:bg-bblue z-10 absolute left-0 top-0 lg:max-w-[591px] text-white lg:block pl-5 pr-5 lg:pl-16 lg:pr-0 contractor-container">
          <div className="lg:mt-[7em] mt-[5em] flex flex-col justify-center items-center lg:block">
            <h1 className="font-SatoshiBold text-4xl lg:w-full md:text-5xl xl:text-6xl text-center lg:text-left">
              Gain access to bid on a steady pool of construction projects
            </h1>
            <p className="font-SatoshiMedium max-w-[100%] mt-8 text-base md:text-xl text-center lg:text-left">
              Sign up and get your company's profile in front of hundreds of
              project owners for free.
            </p>

            <div className=" mt-8 w-full flex lg:flex-row flex-col items-center lg:gap-[2em] gap-[1em]">
              <Button
                style={{
                  backgroundColor: "#fff",
                  color: "#437ADB",
                }}
                label="Create profile"
                className="md:w-auto w-full"
                onClick={() =>
                  window.location.assign(
                    `https://auth.${process.env.REACT_APP_DOMAIN}`
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="relative w-full bg-bblue h-full lg:flex-row flex-col justify-between items-center lg:flex hidden">
          <div
            className="absolute top-0 lg:left-[17%] xl:left-[16%] bg-bblue slanted-bg lg:w-[60%] xl:w-[50%] h-full "
            style={{
              clipPath: "polygon(0 0, 68% 0, 100% 100%, 0% 100%)",
              WebkitClipPath: "polygon(0 0,68% 0, 100% 100%, 0% 100%)",
            }}
          ></div>

          <div className="w-[45%] h-full bg-bblue"></div>

          <div className="w-[55%] h-full">
            <img
              src={construction}
              alt="Construction Image"
              className="object-cover max-h-screen"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
