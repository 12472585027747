import { ContactModal } from "pages/enterprises/components/HeroSection";
import React, { useState, useEffect } from "react";

interface PlanProps {
  plans: {
    title: string;
    amount: string;
    user: string;
    free: string;
    buttonTitle: string;
    type: string;
    benefits: string[];
    hasDropdown?: boolean;
    baseAmount: number;
  }[];
  selectedPlan: string | null;
  setSelectedPlan: (plan: string) => void;
}

const PricingPlans: React.FC<PlanProps> = ({
  plans,
  selectedPlan,
  setSelectedPlan,
}) => {
  const [selectedSeats, setSelectedSeats] = useState<{ [key: string]: number }>(
    {
      Pro: 3,
    }
  );
  const getSeatOptions = (planTitle: string) => {
    if (planTitle === "Pro") {
      return Array.from({ length: 8 }, (_, i) => i + 3);
    }

    return Array.from({ length: 10 }, (_, i) => i + 1);
  };

  const handleSeatChange = (planTitle: string, seats: number) => {
    setSelectedSeats((prev) => ({
      ...prev,
      [planTitle]: seats,
    }));
  };

  const computeCost = (planTitle: string, seats: number) => {
    if (planTitle === "Pro") {
      return seats <= 3 ? 45000 : 45000 + (seats - 3) * 10000;
    }
    return seats * 10000;
  };

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  return (
    <>
      {modal && <ContactModal closer={toggleModal} />}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 xl:gap-16 px-4 md:px-2">
        {plans.map((plan) => {
          const seats = selectedSeats[plan.title] || 1;

          // Updated amount based on selected seats
          const updatedAmount = `NGN ${(
            plan.baseAmount * seats
          ).toLocaleString()}`;

          return (
            <div
              onClick={() => setSelectedPlan(plan.title)}
              key={plan.title}
              className={`border rounded-2xl p-6 shadow-sm hover:shadow-2xl transition-shadow duration-300 ${
                selectedPlan === plan.title ? "bg-[#ECF2FB]" : ""
              } w-full lg:w-[300px]`}
            >
              <h3 className="text-lg md:text-xl font-semibold text-left">
                {plan.title}
              </h3>

              {plan.title === "Pro" ? (
                <p className="text-xl md:text-2xl font-semibold mt-3 text-left">
                  {`NGN ${computeCost(plan.title, seats).toLocaleString()}`}
                </p>
              ) : (
                plan.amount && (
                  <p
                    className={`text-xl md:text-2xl font-semibold mt-3 text-left ${
                      selectedPlan === plan.title ? "text-[#437ADB]" : ""
                    }`}
                  >
                    {updatedAmount}
                  </p>
                )
              )}

              <p className="text-gray-600 text-left">{plan.user}</p>
              <p className="mt-3 text-lg md:text-2xl font-SatoshiMedium text-left">
                {plan.type}
              </p>

              <div className="flex flex-col mt-10 md:mt-14">
                {plan.hasDropdown && (
                  <>
                    <div className=" self-start text-lg font-semibold mb-2 sm:-mt-10">
                      No of Users
                    </div>
                    <div className="relative mb-4">
                      <select
                        value={seats}
                        onChange={(e) =>
                          handleSeatChange(plan.title, parseInt(e.target.value))
                        }
                        className={`border py-2 pr-10 pl-2 border-[#9099A8] rounded-md w-full appearance-none transition-colors duration-300 ${
                          selectedPlan === plan.title
                            ? "bg-[#ECF2FB] text-[#77828D]"
                            : "bg-white text-[#9099A8]"
                        }`}
                      >
                        {getSeatOptions(plan.title).map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-[#9099A8]"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </>
                )}

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedPlan(plan.title);
                    toggleModal();
                  }}
                  className={`w-full mb-4 md:mb-6 py-2 md:py-2.5 font-semibold rounded-lg ${
                    !plan.hasDropdown ? "mt-8 md:mt-14" : ""
                  } ${!plan.user ? "mt-10 md:mt-20" : ""}`}
                  style={{
                    backgroundColor:
                      selectedPlan === plan.title ? "#437ADB" : "#ECF2FB",
                    color: selectedPlan === plan.title ? "#fff" : "#437ADB",
                  }}
                >
                  {plan.buttonTitle}
                </button>
              </div>

              <ul className="mt-4">
                <li
                  className={`font-bold text-left ${!plan.free ? "mt-10" : ""}`}
                >
                  {plan.free}
                </li>
                {plan.benefits.map((benefit, i) => (
                  <li key={i} className="flex items-center mt-1">
                    <span className="text-blue-500 text-2xl pr-6">
                      &#x2713;
                    </span>{" "}
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PricingPlans;
