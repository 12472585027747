import React from "react";
import { PremiumHeroSection } from "../components/PremiumHeroSection";
import ProgressCard from "./ProgressCard";
import WhatYouGet from "./whatYouGet";

const PremiumPlan = () => {
	return (
		<div>
			<PremiumHeroSection />
			<ProgressCard />
			<WhatYouGet />
		</div>
	);
};

export default PremiumPlan;
