import { useState } from "react";
import { SmallEnterpriseHeroImage } from "assets/images";
import { LargeEnterPriseHeroImage } from "assets/svg";
import Button from "components/shared/Button";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContactSalesModal from "components/newShared/ContactSalesModal";
export const HeroSection: FC = () => {
  const [modal, setModal] = useState(false);
  let navigate = useNavigate();

  const onClick = () => {
    setModal(!modal);
  };
  return (
    <div className="bg-enterprise bg-cover relative">
      <div className="pt-8 md:pt-16 xl:pt-20 px-5 md:px-16 lg:px-16">
        <div className="h-full w-full flex flex-col justify-center lg:items-center gap-8">
          <div className="lg:hidden w-full border-8 border-solid border-bblack-4 rounded-2xl">
            <img
              src={SmallEnterpriseHeroImage}
              alt="Project Manager Hero"
              className="rounded-lg w-full"
            />
          </div>
          <div className="flex flex-col gap-6 lg:w-[720px] text-center referral-container">
            <p className="font-SatoshiBold  text-white text-5xl lg:text-6xl">
              Manage your construction projects like a Pro
            </p>
            <p className="font-SatoshiMedium  text-bidsbg text-xl">
              Get all the tools and resources you need to manage multiple
              projects and project teams all in one place.
            </p>
          </div>

          <Button label="Contact Sales" onClick={onClick} />

          <div className="hidden lg:block w-[60%] 3xl:w-[50%] m-auto">
            <img
              src={LargeEnterPriseHeroImage}
              alt="Project Manager Hero"
              className="w-full m-auto"
            />
          </div>
        </div>
      </div>
      {modal && <ContactModal closer={() => setModal(false)} />}
    </div>
  );
};
interface Props {
  closer: () => void;
}

export const ContactModal = ({ closer }: Props) => {
  return (
    <div
      onClick={closer}
      className="fixed   w-screen z-[999] h-screen top-0 left-0 flex items-center justify-center bg-black bg-opacity-60"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className=" max-w-[432px] p-4 lg:p-6 rounded-md bg-white w-4/5 md:w-2/3 lg:w-1/2 "
      >
        <ContactSalesModal closer={closer} />
      </div>
    </div>
  );
};
