import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { sendContactForm, resetState } from "../../redux/contactSlice";

const ContactForm = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { loading, success, error, successMessage } = useSelector(
		(state: RootState) => state.contact
	);

	const [formData, setFormData] = useState({
		firstname: "",
		lastname: "",
		email: "",
		message: "",
	});

	const [errors, setErrors] = useState({
		firstname: "",
		lastname: "",
		email: "",
		message: "",
	});

	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	const validateField = (name: string, value: string) => {
		switch (name) {
			case "firstname":
				return value.trim() ? "" : "First Name is required.";
			case "lastname":
				return value.trim() ? "" : "Last Name is required.";
			case "email":
				return /\S+@\S+\.\S+/.test(value)
					? ""
					: "Please enter a valid email address.";
			case "message":
				return value.trim() ? "" : "Please enter a message.";
			default:
				return "";
		}
	};

	const handleBlur = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: validateField(name, value),
		}));
	};

	const handleChange = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));

		// Clear the error when the user starts typing
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const newErrors = {
			firstname: validateField("firstname", formData.firstname),
			lastname: validateField("lastname", formData.lastname),
			email: validateField("email", formData.email),
			message: validateField("message", formData.message),
		};

		setErrors(newErrors);
		if (Object.values(newErrors).some((error) => error)) return;

		dispatch(sendContactForm(formData));
	};

	useEffect(() => {
		if (success) {
			setShowSuccessMessage(true);
			setFormData({
				firstname: "",
				lastname: "",
				email: "",
				message: "",
			});

			const timer = setTimeout(() => {
				setShowSuccessMessage(false);
				dispatch(resetState());
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [success, dispatch]);

	return (
		<form
			className="flex flex-col gap-6 w-full lg:w-6/12 bg-white text-bblack-1 px-4 md:px-8 pt-8 md:pt-14 pb-8 rounded-xl"
			onSubmit={handleSubmit}
		>
			{showSuccessMessage && (
				<div className="p-4 border border-green-400 bg-green-100 rounded-md text-green-600 text-center">
					{successMessage || "Message sent successfully!"}
				</div>
			)}

			<div className="flex flex-col md:flex-row gap-6 items-center w-full">
				<div className="flex flex-col gap-2 w-full md:w-1/2">
					<label htmlFor="firstname" className="font-inter font-medium text-sm">
						First name
					</label>
					<input
						type="text"
						id="firstname"
						name="firstname"
						placeholder="John"
						value={formData.firstname}
						onChange={handleChange}
						onBlur={handleBlur}
						className="px-[14px] py-[10px] border border-ashShade-15 w-full rounded-lg placeholder:text-base placeholder:text-bash focus:outline-0 focus:border-bblue"
					/>
					{errors.firstname && (
						<p className="text-red-500">{errors.firstname}</p>
					)}
				</div>
				<div className="flex flex-col gap-2 w-full md:w-1/2">
					<label htmlFor="lastname" className="font-inter font-medium text-sm">
						Last name
					</label>
					<input
						type="text"
						id="lastname"
						name="lastname"
						placeholder="Doe"
						value={formData.lastname}
						onChange={handleChange}
						onBlur={handleBlur}
						className="px-[14px] py-[10px] border border-ashShade-15 w-full rounded-lg placeholder:text-base placeholder:text-bash focus:outline-0 focus:border-bblue"
					/>
					{errors.lastname && <p className="text-red-500">{errors.lastname}</p>}
				</div>
			</div>
			<div className="flex flex-col gap-2">
				<label htmlFor="email" className="font-inter font-medium text-sm">
					Email
				</label>
				<input
					type="email"
					id="email"
					name="email"
					placeholder="name@example.com"
					value={formData.email}
					onChange={handleChange}
					onBlur={handleBlur}
					className="px-[14px] py-[10px] border border-ashShade-15 w-full rounded-lg placeholder:text-base placeholder:text-bash focus:outline-0 focus:border-bblue"
				/>
				{errors.email && <p className="text-red-500">{errors.email}</p>}
			</div>
			<div className="flex flex-col gap-2">
				<label htmlFor="message" className="font-inter font-medium text-sm">
					Description
				</label>
				<textarea
					id="message"
					name="message"
					placeholder="Enter a description..."
					value={formData.message}
					onChange={handleChange}
					onBlur={handleBlur}
					className="px-[14px] py-[10px] border border-ashShade-15 w-full min-h-32 rounded-lg placeholder:text-base placeholder:text-bash resize-none focus:outline-0 focus:border-bblue"
				/>
				{errors.message && <p className="text-red-500">{errors.message}</p>}
			</div>
			<button
				type="submit"
				className="bg-bblue text-white py-3 rounded-lg font-SatoshiRegular font-medium hover:bg-blueShades-1 active:bg-blueShades-0"
				disabled={loading}
			>
				{loading ? "Sending..." : "Send message"}
			</button>
		</form>
	);
};

export default ContactForm;
