import Button from "components/shared/Button";
import { FC } from "react";

import { Link } from "react-router-dom";

export const HeroSection: FC = () => {
  return (
    <div className="relative w-full ">
      <div className="write-up  top-0 left-0 right-0 px-4 md:px-0 mt-8 md:mt-2 xl:mt-6 3xl:mt-28 4xl:mt-28 mb-10 flex flex-col mx-auto max-w-full md:max-w-[700px] xl:max-w-[800px] text-center">
        <span className="font-SatoshiBold text-[2rem] md:text-[2.5rem] xl:text-[3.8rem] 3xl:text-[5rem] 4xl:text-[5.5rem] leading-tight">
          <span className="block">Choose the plan that is right for you</span>
          {/* <span className="max-w-full md:max-w-[60%] xl:max-w-[60%] mx-auto">
            for you.
          </span> */}
        </span>
      </div>
      <div className="heroTab"></div>
      <div className="cards"></div>
    </div>
  );
};
