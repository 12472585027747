import { FC } from "react";
import { EstimateSection } from "components";
import { HeroSection } from "./components/HeroSection";
import { FeaturesSection } from "./components/FeaturesSection";
import { FaqSection } from "components/containers/FaqSection";
export const EnterprisePage: FC = () => {
  return (
    <>
      <HeroSection />
      <FeaturesSection />
      <FaqSection />
      <EstimateSection />
    </>
  );
};
